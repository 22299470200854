import React, { useState,  useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Link from '@material-ui/core/Link';
import Menu from '@material-ui/core/Menu';
import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import Avatar from '@material-ui/core/Avatar';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Formik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Axios from 'axios';
import * as Yup from 'yup';
import citySchema from '../data/citySchema';
import colors from '../themes/colors';
import * as Constants from './../utilities/Constants';

let themeType = citySchema['city']['heartland'].theme

const lightColor = 'rgba(54,178,187, 1)';


const styles = theme => ({
  secondaryBar: {
    zIndex: 0,
    backgroundColor: colors.background[themeType],
    color: '#36b2bb'
  },
  appBar: {
    backgroundColor: colors.background[themeType]
  },
  menuButton: {
    marginLeft: -theme.spacing(1),
  },
  iconButtonAvatar: {
    padding: 4,
  },
  link: {
    textDecoration: 'none',
    color: lightColor,
    '&:hover': {
      color: '#f50057',
    },
  },
  button: {
    borderColor: lightColor,
  },
  indicator: {
    backgroundColor: '#f50057 !important',
  },
  label: {
    textAlign: "center",
    color: '#36b2bb'
  },
  header: {
    textTransform: "capitalize",
    fontSize: '24px',
    backgroundColor: colors.background[themeType],
    color: colors.primaryFont[themeType]
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    // top: '-20%',
    position: 'relative',
    width: '40%',
  },
});



function Header(props) {
  // for get user name 
  let token = localStorage.getItem('mcomp_token');
  useEffect(() => {
    Axios
      .get(Constants.NODE_API_BASE_URL+`/api/users/profile`, {
        headers: { 'authorization': token },
      })
      .then(res => {
        setlist(res['data']['users']);
      })
      .catch(err => {
        console.log("errorro", err)
      })
  }, []);



  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [open, setOpen] = React.useState(false);
  const [lists, setlist] = useState([]);


  const openProfile = () => {
    setOpen(true);
    setAnchorEl(null);
  };

  const handleClosed = () => {
    setOpen(false);
  };
  
  const [errorResponseMessage, setErrorResponseMessage] = useState('');
  const divStyle = {
    width: '100%'
  };
  const errorMsg = {
    color: 'red',
    textAlign: 'center',
    marginBottom: '20px',
  }



  //********************************************* */ header End
  const { classes, onDrawerToggle, view, data, getClicked } = props;
  let { position } = props;
  const [value, setValue] = React.useState(0);

  /* the function that sets the state for the tab selected */
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /* handles when the tab is clicked */
  const onClick = (e) => {
    position = false
    /* this functions sends which tab is clicked through the props up to paperbase.js */
    getClicked(e.target.innerText)
    console.log(position, value, e.target.innerText)
  }
  return (
    <React.Fragment>
      <AppBar className={classes.appBar} color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Hidden smUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={onDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item xs className={classes.label}></Grid>
            <Grid item>
              <Link className={classes.link} href="/logout" variant="body2">
                Log out
              </Link>
            </Grid>
            <Grid item>
              <Tooltip title="Alerts • No alters">
                <IconButton color="secondary">
                  <NotificationsIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
              <Avatar src={require("../assets/user.png")} alt="My Avatar" />
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>Profile</MenuItem>
              <MenuItem onClick={openProfile}>Change Password</MenuItem>
            </Menu>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={open}
              onClose={handleClosed}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <div className={classes.paper}>
                  <h2 id="transition-modal-title">Change Password</h2>
                  <h4 style={errorMsg}>{errorResponseMessage}</h4>
                  <Formik
                    initialValues={{ password: '', cpassword: '' }}
                    onSubmit={(values, { setSubmitting }) => {
                      setSubmitting(true);
                      let user = {
                        email: lists.email,
                        password: values.password
                      }
                      if (values.password === values.cpassword) {
                        Axios.post(Constants.NODE_API_BASE_URL+`/api/users/set-password`, user).then(res => {
                          // setEmsg(true);
                        }).catch(err => {
                          setSubmitting(false);
                          if (err.response) {
                            //Sometime error message is commin in errors object and sometimes without object
                            // so we are handling it here 
                            if (err.response.data.errors && err.response.data.errors['message']) {
                              setErrorResponseMessage(err.response.data.errors['message']);
                            }

                            if (err.response.data.error) {
                              setErrorResponseMessage(err.response.data.error);
                            }
                          }
                        })

                      }
                      else {
                        setErrorResponseMessage('Password and Confirm Password does not Matched!')
                        setSubmitting(false);
                        toast.error()
                      }
                    }
                    }

                    validationSchema={Yup.object().shape({
                      password: Yup.string()
                        .required('Password is Required'),
                      cpassword: Yup.string()
                        .required('Confirm Password is Required'),
                    })}
                  >
                    {(props) => {
                      const {
                        values,
                        touched,
                        errors,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                      } = props;
                      return (
                        <form onSubmit={handleSubmit}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                              <TextField style={divStyle}
                                label="Password"
                                name="password"
                                variant="outlined"
                                className={classes.textField}
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.password && touched.password}
                                helperText={(errors.password && touched.password) && errors.password}
                                margin="normal"
                                onClick={() => { setErrorResponseMessage('') }}
                                type="password"
                              />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <TextField style={divStyle}
                                label="Confirm Password"
                                name="cpassword"
                                variant="outlined"
                                className={classes.textField}
                                value={values.cpassword}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.cpassword && touched.cpassword}
                                helperText={(errors.cpassword && touched.cpassword) && errors.cpassword}
                                margin="normal"
                                onClick={() => { setErrorResponseMessage('') }}
                                type="password"
                              />
                            </Grid>



                          </Grid>
                          <Grid style={{ 'marginTop': '30px' }} >
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              className={classes.submit}
                              disabled={isSubmitting}>Save</Button>
                            <Button style={{ 'marginLeft': '20px', }}
                              type="button"
                              variant="contained"
                              color="secondary"
                              className={classes.submit}
                              onClick={handleClosed} >Cancel</Button>
                          </Grid>

                        </form>
                      );
                    }}
                  </Formik>
              <ToastContainer/>
                </div>
              </Fade>

            </Modal>


            {/* user profilew End */}
          </Grid>
        </Toolbar>
      </AppBar>
      <Typography color="textSecondary" align="center" className={classes.header}>{view}</Typography>
      <AppBar
        component="div"
        className={classes.secondaryBar}
        color="primary"
        position="static"
        elevation={0}
      >

      </AppBar>
      <AppBar
        component="div"
        className={classes.secondaryBar}
        position="static"
        elevation={0}
      >
        <Tabs value={citySchema['city'][view]['tabs'][0][data].length === 1 ? 0 : value} onChange={handleChange} classes={{ indicator: classes.indicator }}>
          {citySchema['city'][view]['tabs'].map(tab => (
            tab[data] && (
              tab[data].map(name => (
                <Tab
                  label={name}
                  onClick={onClick}
                />
              ))
            )
          ))}
        </Tabs>
      </AppBar>
    </React.Fragment>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  onDrawerToggle: PropTypes.func.isRequired,
};

export default withStyles(styles)(Header);