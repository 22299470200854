import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';

import './userList.css';
import Axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Constants from './../utilities/Constants';

import { withRouter } from 'react-router-dom';


// Style Theme 
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginTop:'20px'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '100%',
        maxWidth: '100%',
    },

    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

const MenuProps = {
    PaperProps: {
        style: {
            width: 250
        },
    },
};




function Search(prop) {
    let token = localStorage.getItem('mcomp_token'); //get token
    const classes = useStyles();
    //Initaialize Form value
    let formObj = {
        'state': '', 'type_of_data': [],
        'date_end': '', 'date_start': '', 'time_start': '',
        'time_end': '', 'speed_lower': '', 'speed_upper': '',
        'lat_north': '', 'lat_south': '', 'lat_east': '', 'lat_west': ''
    };
    const [formValue, setFormValue] = useState(formObj); // all set form value 

    //set validation 
    let initialValidationObj = {
        'disableda': false, 'disabledb': true, 'disabledc': true, 'disabledd': true
    }
    const [validationField, setValidationField] = useState(initialValidationObj);

    // state event start 
    const [stateLoader, setStateLoader] = useState(false); // After Select  state Loder will appear
    const [type, setType] = useState([])  // select state  from input type of state
    const selectState = (event) => {
        setStateLoader(true);
        // setValidationField({...validationField ,'disableda':true});
        setTypeData([]);
        setFormValue({ ...formValue, state: event.target.value });
        Axios
            .post(Constants.NODE_API_BASE_URL + `/api/search/typeData`, { ...formValue, state: event.target.value }, {
                headers: { 'Authorization': 'Token ' + token },
            })
            .then(res => {
                setValidationField({ ...validationField, 'disableda': false, 'disabledb': false });
                setType(res.data);
                setStateLoader(false) // disable loading here
            })
            .catch(err => {
                console.log("error", err);
            })
    }

    // type of data start
    const [typeData, setTypeData] = React.useState([]);
    const [dateLoader, setDateLoader] = useState(false); // After Select Type of date Loder will appear
    const handleChange = event => {
        setTypeData(event.target.value);
        setFormValue({ ...formValue, type_of_data: event.target.value });
        setDateLoader(true) // enable loading here
        Axios
            .post(Constants.NODE_API_BASE_URL + `/api/search/dateAvailable`, { ...formValue, type_of_data: event.target.value }, {
                headers: { 'Authorization': 'Token ' + token },
            })
            .then(res => {
                setValidationField({ ...validationField, 'disableda': false, 'disabledb': false, 'disabledc': false });
                let apiDate;
                let dateArr = [];
                res['data'].forEach(value => {
                    apiDate = new Date(value);
                    dateArr.push(apiDate);
                });
                setDateLoader(false) // disable loading here
                setIncludeDatesArray(dateArr);
            })
            .catch(err => {
                console.log("error", err);
            })
    };

    // Submit Form 
    const onSubmit = () => {
        setValidationField({ ...validationField, 'disabledd': true });
        // alert("Something went wrong. Please try again.");
        Axios
            .post(Constants.NODE_API_BASE_URL + `/api/search`, formValue, {
                headers: { 'Authorization': 'Token ' + token },
            })
            .then(res => {
                if (res.data.success.result) {
                    window.location.href = "/requestHistory";
                }else{
                    setMsg(true)
                    alert("Something went wrong. Please try again.");
                    setValidationField({ ...validationField, 'disabledd': false });

                }
            })
            .catch(err => {
                setMsg(true);
                // setErrorMsg(err.error)
                console.log("error", err);
                // toast.error("Something went wrong");
            })
    }
    //Back button Style
    const btnRight = {
        "float": "right", "background": "#3bb7bf", "color": " #fff", "borderRadius": "4px"
    }


    // Date set in text field
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [includeDatesArray, setIncludeDatesArray] = useState([]);
    const [errorMsg, setErrorMsg] = useState('Something went wrong. Please try again.');
    const [Msg, setMsg] = useState(false);

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={7} className="m-auto">
                    <Typography component={'h5'} variant={'h5'}>
                        Search Traffic Data
                        <Button color="primary" style={btnRight} onClick={() => { window.location.href = "/requestHistory" }}>
                            View Previous Request</Button>
                    </Typography>
                   { Msg &&  <Typography component={'h6'} variant={'h6'} className="errmsg">
                        {errorMsg}
                    </Typography>}
                    <Paper className={classes.paper}>
                        <form className={classes.root} noValidate autoComplete="off">
                            <FormControl className={classes.formControl} >
                                <InputLabel id="state-label">Select State<sup>*</sup> </InputLabel>
                                <Select
                                    labelId="state-label"
                                    id="state"
                                    onChange={selectState}
                                    fullWidth
                                    defaultValue=""
                                    disabled={(validationField.disableda)}
                                >
                                    <MenuItem value="ia">Iowa</MenuItem>
                                    <MenuItem value="ks">Kansas</MenuItem>
                                    <MenuItem value="mo">Missouri</MenuItem>
                                    <MenuItem value="ok">Oklahoma</MenuItem>
                                </Select>
                            </FormControl>
                            {stateLoader === true &&
                                <div className={classes.root}>
                                    <CircularProgress /> Loading  types of data
                                </div>
                            }
                            <FormControl className={classes.formControl}>
                                <InputLabel id="typeOfData">Select Type of Data<sup>*</sup></InputLabel>
                                <Select
                                    labelId="typeOfData"
                                    id="demo-mutiple-checkbox"
                                    multiple
                                    value={typeData}
                                    onChange={handleChange}
                                    input={<Input />}
                                    renderValue={selected => selected.join(', ')}
                                    MenuProps={MenuProps}
                                    disabled={(validationField.disabledb)}
                                >
                                    {type.map(type => (
                                        <MenuItem key={type} value={type}>
                                            <Checkbox checked={typeData.indexOf(type) > -1} />
                                            <ListItemText primary={type} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {dateLoader === true &&
                                <div className={classes.root}>
                                    <CircularProgress /> Loading  available dates
                                </div>
                            }
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <InputLabel id="startDate" style={{ marginTop: '20px', textAlign: "left", marginLeft: '8px' }}>From Date<sup>*</sup></InputLabel>
                                    <DatePicker
                                        disabled={(validationField.disabledc)}
                                        selected={startDate}
                                        onChange={date => {
                                            setFormValue({ ...formValue, date_start: date }); setStartDate(date);
                                            setValidationField({ ...validationField, 'disableda': true, 'disabledb': true, 'disabledc': false, 'disabledd': false });
                                        }}
                                        includeDates={includeDatesArray}
                                        placeholderText="Start Date"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabel id="endDate" style={{ marginTop: '20px', textAlign: "left", marginLeft: '8px' }}>To Date<sup>*</sup></InputLabel>
                                    <DatePicker
                                        disabled={(validationField.disabledc)}
                                        selected={endDate}
                                        onChange={date => {
                                            setFormValue({ ...formValue, date_end: date }); setEndDate(date);
                                            setValidationField({ ...validationField, 'disableda': true, 'disabledb': true, 'disabledc': false, 'disabledd': false });
                                        }}
                                        includeDates={includeDatesArray}
                                        placeholderText="End Date"
                                    />
                                </Grid>
                            </Grid>
                            <Button variant="contained" color="primary" className="createButton"
                                disabled={(validationField.disabledd)}
                                onClick={onSubmit}>
                                Create Request
                            </Button>
                        </form>
                    </Paper>
                </Grid>
            </Grid>

        </div>
    )
}

// export default Search
export default withRouter(Search);
