import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import ReactGA from "react-ga";
import NewHeader from "./NewHeader";
import Table from "@material-ui/core/Table";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import * as Constants from "./../../utilities/Constants";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import TableContainer from "@material-ui/core/TableContainer";

// import array
import { allReports } from "./ArrayData";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  formControl: {
    margin: theme.spacing(2),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  link: {
    display: "flex",
    flexDirection: "row",
  },
}));

const NewReport = () => {
  const classes = useStyles();

  const [labelOne, setLabelOne] = useState();
  const [labelTwo, setLabelTwo] = useState();
  const [labelThree, setLabelThree] = useState();

  useEffect(() => {
    ReactGA.initialize(Constants.GOOGLE_ANALYTICS_KEY);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const GAEvent = (title, link) => {
    console.log("event", title);
    ReactGA.event({
      category: "Report: " + title,
      action: link,
    });
  };

  const handleChangeOne = (event) => {
    setLabelOne(event.target.value);
  };
  const handleChangeTwo = (event) => {
    setLabelTwo(event.target.value);
  };
  const handleChangeThree = (event) => {
    setLabelThree(event.target.value);
  };

  //  For Report
  const reportItems = allReports.filter((item) => {
    return item.type === "Report";
  });

  const arrReport = reportItems.filter((item) => {
    return item.year === labelTwo && item.quater === labelThree;
  });

  // For Sensors
  const sensorItems = allReports.filter((item) => {
    return item.type === "Erroneous Sensors";
  });

  const arrSensors = sensorItems.filter((item) => {
    return item.year === labelTwo && item.quater === labelThree;
  });

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <NewHeader />
        </Grid>
        <Grid item xs={10} style={{ margin: "auto" }}>
          <Grid container spacing={5} className="mb-100 c2cHeight">
            <Grid item xs={12} md={4}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Category</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  defaultValue=""
                  value={labelOne}
                  onChange={handleChangeOne}
                >
                  <MenuItem value={"all"}>All</MenuItem>
                  <MenuItem value={"report"}>Report</MenuItem>
                  <MenuItem value={"erroneous_sensor"}>
                    Erroneous Sensors
                  </MenuItem>
                  <MenuItem value={"documentation"}>Documentation</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {labelOne && labelOne !== "documentation" && labelOne !== "all" && (
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">Year</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={labelTwo}
                    onChange={handleChangeTwo}
                  >
                    <MenuItem value={"2019"}>2019</MenuItem>
                    <MenuItem value={"2020"}>2020</MenuItem>
                    <MenuItem value={"2021"}>2021</MenuItem>
                    <MenuItem value={"2022"}>2022</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}
            {labelOne && labelOne !== "documentation" && labelOne !== "all" && (
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">
                    Quaterly Report
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={labelThree}
                    onChange={handleChangeThree}
                  >
                    <MenuItem value={"Q1"}>Q1</MenuItem>
                    <MenuItem value={"Q2"}>Q2</MenuItem>
                    <MenuItem value={"Q3"}>Q3</MenuItem>
                    <MenuItem value={"Annual Report"}>Annual Report</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}

            {/* Display data */}
            <Grid
              item
              xs={12}
              style={{ paddingLeft: "28px", paddingRight: "0px" }}
            >
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Item</TableCell>
                      <TableCell align="center">Link</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>

                  {/*All Report Data  */}
                  {labelOne && labelOne === "all" && (
                    <TableBody>
                      {allReports.map((item) => (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            {item.disp}
                          </TableCell>
                          <TableCell align="center"> {item.link}</TableCell>
                          <TableCell align="center">
                            <Button variant="contained" color="primary">
                              <a
                                href={item.link}
                                target="_blank"
                                onClick={(e) => {
                                  GAEvent(`"${item.disp}"`, `"${item.link}"`);
                                }}
                                style={{
                                  color: "#fff",
                                  textDecoration: "none",
                                }}
                              >
                                Open
                              </a>
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}

                  {/* For Documentation */}
                  {labelOne && labelOne === "documentation" && (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {labelOne}
                      </TableCell>
                      <TableCell align="center">
                        {" "}
                        https://drive.google.com/file/d/156CnYVkFMMZW6cgopQKO-NYM09urdMAs/view?usp=sharing
                      </TableCell>
                      <TableCell align="center">
                        <Button variant="contained" color="primary">
                          <a
                            href={
                              "https://drive.google.com/file/d/156CnYVkFMMZW6cgopQKO-NYM09urdMAs/view?usp=sharing"
                            }
                            target="_blank"
                            onClick={(e) => {
                              GAEvent(
                                "Delay Definition",
                                "https://drive.google.com/file/d/156CnYVkFMMZW6cgopQKO-NYM09urdMAs/view?usp=sharing"
                              );
                            }}
                            style={{ color: "#fff", textDecoration: "none" }}
                          />
                          Open
                        </Button>
                      </TableCell>
                    </TableRow>
                  )}

                  {/* Report */}

                  {labelOne === "report" && labelTwo && labelThree && (
                    <TableBody>
                      {arrReport.map((item) => (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            {item.disp}
                          </TableCell>
                          <TableCell align="center">{item.link}</TableCell>
                          <TableCell align="center">
                            <Button variant="contained" color="primary">
                              <a
                                href={item.link}
                                target="_blank"
                                onClick={(e) => {
                                  GAEvent(`"${item.disp}"`, `"${item.link}"`);
                                }}
                                style={{
                                  color: "#fff",
                                  textDecoration: "none",
                                }}
                              >
                                Open
                              </a>
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}

                  {/* Erroneous Sensors */}

                  {labelOne === "erroneous_sensor" && labelTwo && labelThree && (
                    <TableBody>
                      {arrSensors.map((item) => (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            {item.disp}
                          </TableCell>
                          <TableCell align="center">{item.link}</TableCell>
                          <TableCell align="center">
                            <Button variant="contained" color="primary">
                              <a
                                href={item.link}
                                target="_blank"
                                onClick={(e) => {
                                  GAEvent(`"${item.disp}"`, `"${item.link}"`);
                                }}
                                style={{
                                  color: "#fff",
                                  textDecoration: "none",
                                }}
                              >
                                Open
                              </a>
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper className={`${classes.paper} bgFooter`} style={{ top: 0 }}>
          <hr />
          <p className="footerNew">Copyright © Etalyc 2020.</p>
        </Paper>
      </Grid>
    </div>
  );
};

export default NewReport;
