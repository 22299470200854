import React,{ useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ReactGA from "react-ga";

import NewHeader from './NewHeader';
import * as Constants from './../../utilities/Constants';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
}));

function NewHome() {
    useEffect(() => {
        ReactGA.initialize(Constants.GOOGLE_ANALYTICS_KEY);
        ReactGA.pageview(window.location.pathname + window.location.search); 
      }, []);
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={12}>
                    <NewHeader />
                </Grid>
                <Grid item xs={12}>
                    <img src="/map.png" className="img-fluid mb-100" 
                    style={{ width: '70%',margin: 'auto',marginTop: '80px',display: 'block', }} alt="img" />
                    {/* <img src="etalyc_logo_white.png" style={{ width: '100px',position:'absolute',top:'49%',right:'46%'}} alt="Etalyc"/> */}
                </Grid>
                <Grid item xs={12}>
                    <div className="container">
                        <Paper className={classes.paper}>
                            <div className="padding12">
                                <p className="justify">
                                    The Integrated Data Warehouse (IDW) is cloud-based, scalable,
                                    and distributed data warehouse system, designed for storage and integration
                                    of massive structured and unstructured traffic data from different sources in
                                    Heartland States, including probe data, sensor data, incident reports, etc.
                                    The system will handle fast, disparate, noisy data streams with ease and
                                    will also provide users a
                                    high throughput access to the integrated datasets, and transform heterogeneous
                                    streams of data to usable knowledge.
                                </p>



                              The main functions provided are:
                              <ul className="ml52">
                                    <li>Interactive performance dashboard from probe data</li>
                                    <li>Quarterly performance reporting on system health and mobility</li>
                                    <li>C2C live data download</li>
                                    <li>Batch data search and download</li>
                                </ul>

                            </div>
                        </Paper>
                    </div>
                </Grid>

            </Grid>

            <Grid item xs={12}>
                <Paper className={`${classes.paper} bgFooter`}>
                    <hr />
                    <p className="footerNew">Copyright © Etalyc 2020.</p>
                </Paper>
            </Grid>

        </div>
    )
}

export default NewHome
