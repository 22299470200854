import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';
// import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';

import axios from 'axios';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import * as Constants from './../utilities/Constants';

function MadeWithLove() {
    return (
        <Typography variant="body2" color="textSecondary" align="center" style={{ paddingBottom: '25px' }}>
            {'Copyright © '}
            <Link color="inherit" to="https://etalyc.com/" style={{ color: '#5d6163' }}>
                Etalyc.com
        </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    container: {
        backgroundColor: '#ffffff',
        //padding: '5px',
    }
}));

export default function SaveUserPasswordFirstTime(props) {
    const classes = useStyles();
    const [errorResponseMessage, setErrorResponseMessage] = useState('');
    const [confirmEmailErrorMessage, setConfirmEmailErrorMessage] = useState(false);
    //Loader
    const [loader, setLoader] = useState(false);
    const [passwordChanged, setPasswordChanged] = useState(false);
    const divStyle = {
        width: '100%'
    };
    const errorMsg = {
        color: 'red',
        textAlign: 'center'
    }
    const linkColor = {
        color: '#3f51b5',
    };
    const submitHeader = {
        boxShadow: '0px 0px 9px #978e8e',
        padding: '12px',
        background: '#5fa8d6',
        color: '#fff'
    }

    return (
        <Container component="main" maxWidth="xs" className={classes.container}>
            <CssBaseline />
            <div className={classes.paper}>
                <Grid item xs={12}>
                    <img src={require("../assets/etalyc.jpg")} width="400" alt="img" style={{ marginTop: '25px', marginBottom: '25px' }}></img>
                </Grid>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>

                <Typography component="h1" variant="h5">
                    You are logging for first time. Please change your temporary password.
                </Typography>

                {passwordChanged &&

                    <div className={classes.form}>
                        <h4 style={submitHeader}>
                            Password changed successfully. Click here to  <Link to="/portal" variant="body2" style={linkColor}>
                                {"Login"}
                            </Link>
                        </h4>
                    </div>
                }
                <div className={classes.form} noValidate>
                    <h4 style={errorMsg}>{errorResponseMessage}</h4>
                    {loader === true &&
                        <div className={classes.root}>
                            <CircularProgress />
                        </div>
                    }

                    <Formik
                        initialValues={{ password: '', cpassword: '' }}
                        onSubmit={(values, { setSubmitting }) => {
                            setLoader(true);
                            if (values.password === values.cpassword) {
                                let user = {
                                    email: props.history.location.state.email,
                                    password: values.password
                                }
                                axios.post(Constants.NODE_API_BASE_URL + `/api/users/set-password`, user)
                                    .then(res => {
                                        toast.success("Password changed successfully.")
                                        setLoader(false);
                                        setSubmitting(true);
                                        setPasswordChanged(true);
                                    }).catch(err => {
                                        toast.error('Error')
                                        setLoader(false);
                                        props.history.push('/');
                                        setSubmitting(false);
                                    })

                            } else {
                                setLoader(false);
                                setConfirmEmailErrorMessage(true)
                                setSubmitting(false);
                            }

                        }
                        }

                        validationSchema={Yup.object().shape({
                            password: Yup.string()
                                .required('Password is Required'),
                            cpassword: Yup.string()
                                .required('Confirm Password is Required'),
                        })}
                    >
                        {(props) => {
                            const {
                                values,
                                touched,
                                errors,
                                isSubmitting,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                            } = props;
                            return (
                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12}>
                                            <TextField style={divStyle}
                                                type="password"
                                                label="Password"
                                                name="password"
                                                variant="outlined"
                                                value={values.password}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.password && touched.password}
                                                helperText={(errors.password && touched.password) && errors.password}
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField style={divStyle}
                                                type="password"
                                                label="Confirm Password"
                                                name="cpassword"
                                                variant="outlined"
                                                value={values.cpassword}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.cpassword && touched.cpassword}
                                                helperText={(errors.cpassword && touched.cpassword) && errors.cpassword}
                                                margin="normal"
                                            />
                                            {confirmEmailErrorMessage &&
                                                <p style={errorMsg}>
                                                    Password and Confirm Password does not Matched!
                                                </p>
                                            }
                                        </Grid>
                                    </Grid>

                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                        disabled={isSubmitting}
                                    >
                                        Save
                                    </Button>


                                </form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
            <ToastContainer />
            <Box mt={5}>
                <MadeWithLove />
            </Box>
        </Container>
    );
}