import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import LoginPage from "./auth/LoginPage";
import Logout from "./auth/Logout";
import Signup from "./auth/Signup";
import Soon from "./views/Soon";
import Dashboard from "./dashboard/Paperbase";
import c2c from "./components/c2c";
import RequestHistory from "./components/RequestHistory";
import Users from "./views/users";
import UserListAccess from "../src/views/UserListAccess";
import ForgotPassword from "./auth/ForgotPassword";
import ResetPassword from "./auth/ResetPassword";
import SaveUserPasswordFirstTime from "./auth/SaveUserPasswordFirstTime";
import NewHome from "./components/NewWebsite/NewHome";
import NewDashboard from "./components/NewWebsite/NewDashboard";
import NewReport from "./components/NewWebsite/NewReport";
import NewPortal from "./components/NewWebsite/NewPortal";
import NewAbout from "./components/NewWebsite/NewAbout";
import NewContact from "./components/NewWebsite/NewContact";
import ReactGA from "react-ga";
import * as Constants from "./utilities/Constants";
import NewReportTwo from "./components/NewWebsite/NewReportTwo";
//===
/* this needs to refacored */
let token = localStorage.getItem("mcomp_token");

function App() {
  useEffect(() => {
    ReactGA.initialize(Constants.GOOGLE_ANALYTICS_KEY);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="App">
      {token ? (
        <Router>
          <Switch>
            <Route exact path="/">
              <Redirect to="/dashboard" />
            </Route>
            <Route path="/logout" component={Logout} />
            <Route path="/soon" component={Soon} />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/c2c" component={c2c} />
            <Route path="/userList" component={UserListAccess} />
            <Route
              path="/changePassword"
              component={SaveUserPasswordFirstTime}
            />
            <Route path="/requestHistory" component={RequestHistory} />
          </Switch>
        </Router>
      ) : (
        <Router>
          <Switch>
            <Route path="/signup" component={Signup} />
            <Route exact path="/" component={NewHome} />
            <Route exact path="/">
              <Redirect to="/home" />
            </Route>
            <Route path="/forgotPassword" component={ForgotPassword} />
            <Route path="/resetPassword/:token" component={ResetPassword} />
            <Route path="/users" component={Users} />
            <Route path="/userList" component={UserListAccess} />
            <Route
              path="/changePassword"
              component={SaveUserPasswordFirstTime}
            />
            <Route path="/requestHistory" component={RequestHistory} />
            <Route path="/home" component={NewHome} />
            <Route path="/dashboard" component={NewDashboard} />
            <Route path="/report" component={NewReport} />
            <Route path="/portal" component={NewPortal} />
            <Route path="/about" component={NewAbout} />
            <Route path="/contact" component={NewContact} />
          </Switch>
        </Router>
      )}
    </div>
  );
}

export default App;
