/*
|**************************************************************************
| QuaterlyReport Component
|**************************************************************************
|  
*/

import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import IconButton from '@material-ui/core/IconButton';
import LastPageIcon from '@material-ui/icons/LastPage';
import TableFooter from '@material-ui/core/TableFooter';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import ReactGA from 'react-ga';
import Axios from 'axios';
import * as Constants from './../utilities/Constants';

/***
 * Module Name : QuaterlyReport
 * Description : 
 * Auther :
 * Date : 
 */

const useStyles1 = makeStyles(theme => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = event => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = event => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = event => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
    useEffect(() => {
        ReactGA.initialize(Constants.GOOGLE_ANALYTICS_KEY);
        ReactGA.pageview(window.location.pathname + window.location.search); 
      }, []);


    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};


function QuaterlyReport() {
    const [lists, setList] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, lists.length - page * rowsPerPage);
    let token = localStorage.getItem('mcomp_token');

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const GAEvent = (event, newPage) => {
        console.log("ssss",event);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    return (
        <div>
            <TableContainer component={Paper} className="width-80">
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Item</TableCell>
                            <TableCell align="center">Link</TableCell>
                            <TableCell align="center">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                2019 Q1 - Report
                                </TableCell>
                            <TableCell align="center"> https://drive.google.com/open?id=1xeRbCama7kWwVZ_R8dTeOlIyRlS1X3jL</TableCell>
                            <TableCell align="center">
                                <Button variant="contained" color="primary">
                                    <a href={' https://drive.google.com/open?id=1xeRbCama7kWwVZ_R8dTeOlIyRlS1X3jL'} onClick={(e) => {GAEvent(e)}} target="_blank" style={{ 'color': '#fff', 'textDecoration': 'none' }}>Open</a>
                                </Button>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                            2019 Q1 - IA Sensors
                                </TableCell>
                            <TableCell align="center"> https://drive.google.com/open?id=13BZmroQCXye5_P1GDdTJ5yMPQLiSEta_</TableCell>
                            <TableCell align="center">
                                <Button variant="contained" color="primary">
                                    <a href={' https://drive.google.com/open?id=13BZmroQCXye5_P1GDdTJ5yMPQLiSEta_'} target="_blank" style={{ 'color': '#fff', 'textDecoration': 'none' }}>Open</a>
                                </Button>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                            2019 Q1 - KS Sensors
                                </TableCell>
                            <TableCell align="center"> https://drive.google.com/open?id=1iM_qIpytkMiI8RAEJwWmb3VlqnKQhdtL</TableCell>
                            <TableCell align="center">
                                <Button variant="contained" color="primary">
                                    <a href={'https://drive.google.com/open?id=1iM_qIpytkMiI8RAEJwWmb3VlqnKQhdtL'} target="_blank" style={{ 'color': '#fff', 'textDecoration': 'none' }}>Open</a>
                                </Button>
                            </TableCell>
                        </TableRow>
                        
                        <TableRow>
                            <TableCell component="th" scope="row">
                                2019 Q2 - Report
                                </TableCell>
                            <TableCell align="center">  https://drive.google.com/open?id=1nu9_zrAiye_RBn5QXJvoB2UMrIxZkpNd</TableCell>
                            <TableCell align="center">
                                <Button variant="contained" color="primary">
                                    <a href={'  https://drive.google.com/open?id=1nu9_zrAiye_RBn5QXJvoB2UMrIxZkpNd'} target="_blank" style={{ 'color': '#fff', 'textDecoration': 'none' }}>Open</a>
                                </Button>
                            </TableCell>
                        </TableRow>
                        
                        <TableRow>
                            <TableCell component="th" scope="row">
                            2019 Q2 - IA Sensors
                                </TableCell>
                            <TableCell align="center"> https://drive.google.com/open?id=1ZtyOtNtp1w5qF0hWdl3he5orW1b3GKv5</TableCell>
                            <TableCell align="center">
                                <Button variant="contained" color="primary">
                                    <a href={' https://drive.google.com/open?id=1ZtyOtNtp1w5qF0hWdl3he5orW1b3GKv5'} target="_blank" style={{ 'color': '#fff', 'textDecoration': 'none' }}>Open</a>
                                </Button>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                            2019 Q2 - KS Sensors
                                </TableCell>
                            <TableCell align="center">  https://drive.google.com/open?id=1mwyx3HV0MrrScn_YnoH2dJOnYU625BJq</TableCell>
                            <TableCell align="center">
                                <Button variant="contained" color="primary">
                                    <a href={'  https://drive.google.com/open?id=1mwyx3HV0MrrScn_YnoH2dJOnYU625BJq'} target="_blank" style={{ 'color': '#fff', 'textDecoration': 'none' }}>Open</a>
                                </Button>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                2019 Q3 - Report
                                </TableCell>
                            <TableCell align="center">  https://drive.google.com/open?id=1RnoPal-ezReqyixmHFd0CQRbc9UZZY-9</TableCell>
                            <TableCell align="center">
                                <Button variant="contained" color="primary">
                                    <a href={'  https://drive.google.com/open?id=1RnoPal-ezReqyixmHFd0CQRbc9UZZY-9'} target="_blank" style={{ 'color': '#fff', 'textDecoration': 'none' }}>Open</a>
                                </Button>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                            2019 Q3 - IA Sensors
                                </TableCell>
                            <TableCell align="center">  https://drive.google.com/open?id=1SZhieF2htbcHZUVbf3g5A82Qcmlm0hvh</TableCell>
                            <TableCell align="center">
                                <Button variant="contained" color="primary">
                                    <a href={'  https://drive.google.com/open?id=1SZhieF2htbcHZUVbf3g5A82Qcmlm0hvh'} target="_blank" style={{ 'color': '#fff', 'textDecoration': 'none' }}>Open</a>
                                </Button>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                            2019 Q3 - KS Sensors
                                </TableCell>
                            <TableCell align="center">  https://drive.google.com/open?id=1mbMtHB1Ru223zUm2HbOVyykZ0MoS7Y07</TableCell>
                            <TableCell align="center">
                                <Button variant="contained" color="primary">
                                    <a href={'  https://drive.google.com/open?id=1mbMtHB1Ru223zUm2HbOVyykZ0MoS7Y07'} target="_blank" style={{ 'color': '#fff', 'textDecoration': 'none' }}>Open</a>
                                </Button>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                2019 Annual Report
                                </TableCell>
                            <TableCell align="center">  https://drive.google.com/open?id=15nyo_BzVPQuDeNGOaasEcK8jMAVE-Pam</TableCell>
                            <TableCell align="center">
                                <Button variant="contained" color="primary">
                                    <a href={'  https://drive.google.com/open?id=15nyo_BzVPQuDeNGOaasEcK8jMAVE-Pam'} target="_blank" style={{ 'color': '#fff', 'textDecoration': 'none' }}>Open</a>
                                </Button>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                            2019 Annual - IA Sensors
                                </TableCell>
                            <TableCell align="center">  https://drive.google.com/open?id=1xJCJ181hMvbqn4Tw2ty2DuMaFIKksTcl</TableCell>
                            <TableCell align="center">
                                <Button variant="contained" color="primary">
                                    <a href={'  https://drive.google.com/open?id=1xJCJ181hMvbqn4Tw2ty2DuMaFIKksTcl'} target="_blank" style={{ 'color': '#fff', 'textDecoration': 'none' }}>Open</a>
                                </Button>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                            2019 Annual - KS Sensors
                                </TableCell>
                            <TableCell align="center"> https://drive.google.com/open?id=159-KQ5j38dIo7-zHWNXnf0clY_6SZHyC</TableCell>
                            <TableCell align="center">
                                <Button variant="contained" color="primary">
                                    <a href={' https://drive.google.com/open?id=159-KQ5j38dIo7-zHWNXnf0clY_6SZHyC'} target="_blank" style={{ 'color': '#fff', 'textDecoration': 'none' }}>Open</a>
                                </Button>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                2020 Q1 - Report
                                </TableCell>
                            <TableCell align="center">   https://drive.google.com/open?id=1oU1wP8zJ0XyhrvNSiWWRVGnU77lxAb1W</TableCell>
                            <TableCell align="center">
                                <Button variant="contained" color="primary">
                                    <a href={'   https://drive.google.com/open?id=1oU1wP8zJ0XyhrvNSiWWRVGnU77lxAb1W'} target="_blank" style={{ 'color': '#fff', 'textDecoration': 'none' }}>Open</a>
                                </Button>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                            2020 Q1 - IA Sensors
                                </TableCell>
                            <TableCell align="center">https://drive.google.com/open?id=1dR6FnTATYD5E-Pd6xl8k1OPgCyTC-jE3</TableCell>
                            <TableCell align="center">
                                <Button variant="contained" color="primary">
                                    <a href={'https://drive.google.com/open?id=1dR6FnTATYD5E-Pd6xl8k1OPgCyTC-jE3'} target="_blank" style={{ 'color': '#fff', 'textDecoration': 'none' }}>Open</a>
                                </Button>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                            2020 Q1 - KS Sensors
                                </TableCell>
                            <TableCell align="center">https://drive.google.com/open?id=1kpKpn6pQdtNqr3ufKNP4hvaPKfNhRsh3</TableCell>
                            <TableCell align="center">
                                <Button variant="contained" color="primary">
                                    <a href={'https://drive.google.com/open?id=1kpKpn6pQdtNqr3ufKNP4hvaPKfNhRsh3'} target="_blank" style={{ 'color': '#fff', 'textDecoration': 'none' }}>Open</a>
                                </Button>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                            2020 Q1 - MO Sensors
                                </TableCell>
                            <TableCell align="center">https://drive.google.com/open?id=1vJ3OfKkcxt0Ly3ihtrDDLmdt-9EgDg3n</TableCell>
                            <TableCell align="center">
                                <Button variant="contained" color="primary">
                                    <a href={'https://drive.google.com/open?id=1vJ3OfKkcxt0Ly3ihtrDDLmdt-9EgDg3n'} target="_blank" style={{ 'color': '#fff', 'textDecoration': 'none' }}>Open</a>
                                </Button>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell component="th" scope="row">
                            Delay Definition
                                </TableCell>
                            <TableCell align="center">https://drive.google.com/open?id=156CnYVkFMMZW6cgopQKO-NYM09urdMAs</TableCell>
                            <TableCell align="center">
                                <Button variant="contained" color="primary">
                                    <a href={'https://drive.google.com/open?id=156CnYVkFMMZW6cgopQKO-NYM09urdMAs'} target="_blank" style={{ 'color': '#fff', 'textDecoration': 'none' }}>Open</a>
                                </Button>
                            </TableCell>
                        </TableRow>

                    </TableBody>

                    {/* <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                // colSpan={3}
                                count={lists.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter> */}

                </Table>
            </TableContainer>


        </div>
    )
}

export default QuaterlyReport;