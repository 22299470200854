import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import ReactGA from "react-ga";
import NewHeader from "./NewHeader";
import citySchema from "../../data/citySchema";
import Link from "@material-ui/core/Link";
import * as Constants from "./../../utilities/Constants";

let cityLinks = citySchema["city"]["heartland"].newLinks;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  linklist: {
    width: "75%",
  },
}));

function NewDashboard() {
  const [header, setheaders] = useState([]);
  const [iframe, setIframe] = useState("");
  const [activeCss, setActiveCss] = useState();
  useEffect(() => {
    let cityLinks = citySchema["city"]["heartland"].newLinks;
    let cityLinksLooped = cityLinks[0];
    cityLinksLooped = cityLinksLooped.Performance_Measures;
    setheaders(cityLinksLooped);
    setActiveCss("NPMRDS Summary 2022 Q3");
    setIframe(
      "https://public.tableau.com/views/NPMRDS-DataSummary-2022-Q3/Overview?:embed=y&amp;:showVizHome=no&amp;:display_count=yes&amp;:host_url=https%3A%2F%2Fpublic.tableau.com%2F&amp;:tabs=yes&amp;:toolbar=no&amp;:animate_transition=yes&amp;:display_static_image=no&amp;:display_spinner=yes&amp;:display_overlay=yes&amp;:display_count=yes&amp;publish=yes&amp;:loadOrderID=0"
    );
  }, []);

  useEffect(() => {
    ReactGA.initialize(Constants.GOOGLE_ANALYTICS_KEY);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const handleIframe = (props, key) => {
    ReactGA.event({
      category: "NPMRDS Link: " + key,
      action: props,
    });
    setIframe(props);
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <NewHeader />
        </Grid>
        <Grid item xs={12}>
          <div className="mb-100 container">
            <div className={classes.linklist}>
              <span>
                {Object.keys(header).map((key) => (
                  <Link
                    component="button"
                    variant="body2"
                    // className="navLink"
                    onClick={(e) => {
                      handleIframe(header[key], key);
                      setActiveCss(key);
                    }}
                    key={key}
                    value={header[key]}
                    className={
                      activeCss == key ? " navLink active" : " navLink"
                    }
                  >
                    {key}
                  </Link>
                ))}
              </span>
            </div>
            <iframe
              title="w"
              src={iframe}
              width="100%"
              height="955"
              frameBorder="0"
            ></iframe>
          </div>
          {/* <iframe className="mb-100" title="w" src="https://public.tableau.com/views/NPMRDS-DataSummary-Q1/DataQuality?:embed=y&amp;amp;:showVizHome=no&amp;amp;:display_count=yes&amp;amp;:host_url=https%3A%2F%2Fpublic.tableau.com%2F&amp;amp;:tabs=yes&amp;amp;:toolbar=no&amp;amp;:animate_transition=yes&amp;amp;:display_static_image=no&amp;amp;:display_spinner=yes&amp;amp;:display_overlay=yes&amp;amp;:display_count=yes&amp;amp;publish=yes&amp;amp;:loadOrderID=0" height="955" frameborder="0"></iframe> */}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Paper
          className={`${classes.paper} bgFooter`}
          style={{ top: "-110px" }}
        >
          <hr />
          <p className="footerNew">Copyright © Etalyc 2020.</p>
        </Paper>
      </Grid>
    </div>
  );
}

export default NewDashboard;
