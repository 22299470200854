export default {
  city: {
    heartland: {
      theme: "light",
      navs: [
        {
          Profile: false,
          "Performance Measures": true,
          C2C: false,
          KPIS: false,
          Search: false,
          UserListAccess: false,
          "Quarterly Report": false,
        },
      ],
      tabs: [
        {
          Profile: ["Profile"],
          "Performance Measures": [
            "NPMRDS Summary 2019 Q1",
            "NPMRDS Summary 2019 Q2",
            "NPMRDS Summary 2019 Q3",
            "NPMRDS Summary 2019 Q4",
            "NPMRDS Summary 2020 Q1",
            "NPMRDS Summary 2020 Q2",
            "NPMRDS Summary 2020 Q4",
            "NPMRDS Summary 2022 Q1",
          ],
          C2C: ["Links"],
          KPIS: ["Map"],
          Search: ["Search"],
          UserListAccess: ["UserListAccess"],
          "Quarterly Report": ["Quarterly Report"],
        },
      ],
      links: [
        {
          KPIS: "underdevelopment", //"https://kpis.etalyc.com",
          C2C: "c2c",
          Profile: "users",
          "Performance Measures": {
            // "NPMRDS Summary 2019 Q1": "https://public.tableau.com/views/NPMRDS-DataSummary-Q1/DataQuality?:embed=y&amp;:showVizHome=no&amp;:display_count=yes&amp;:host_url=https%3A%2F%2Fpublic.tableau.com%2F&amp;:tabs=yes&amp;:toolbar=no&amp;:animate_transition=yes&amp;:display_static_image=no&amp;:display_spinner=yes&amp;:display_overlay=yes&amp;:display_count=yes&amp;publish=yes&amp;:loadOrderID=0",
            // "NPMRDS Summary 2019 Q2": "https://public.tableau.com/views/NPMRDS-DataSummary-Q2/DataQuality?:embed=y&amp;:showVizHome=no&amp;:display_count=yes&amp;:host_url=https%3A%2F%2Fpublic.tableau.com%2F&amp;:tabs=yes&amp;:toolbar=no&amp;:animate_transition=yes&amp;:display_static_image=no&amp;:display_spinner=yes&amp;:display_overlay=yes&amp;:display_count=yes&amp;publish=yes&amp;:loadOrderID=0",
            // "NPMRDS Summary 2019 Q3": "https://public.tableau.com/views/NPMRDS-DataSummary-Q3/DataQuality?:embed=y&amp;:showVizHome=no&amp;:display_count=yes&amp;:host_url=https%3A%2F%2Fpublic.tableau.com%2F&amp;:tabs=yes&amp;:toolbar=no&amp;:animate_transition=yes&amp;:display_static_image=no&amp;:display_spinner=yes&amp;:display_overlay=yes&amp;:display_count=yes&amp;publish=yes&amp;:loadOrderID=0",
            // "NPMRDS Summary 2019 Q4": "https://public.tableau.com/views/NPMRDS-DataSummary-Q4/DataQuality?:embed=y&amp;:showVizHome=no&amp;:display_count=yes&amp;:host_url=https%3A%2F%2Fpublic.tableau.com%2F&amp;:tabs=yes&amp;:toolbar=no&amp;:animate_transition=yes&amp;:display_static_image=no&amp;:display_spinner=yes&amp;:display_overlay=yes&amp;:display_count=yes&amp;publish=yes&amp;:loadOrderID=0",
            "NPMRDS Summary 2020 Q1":
              "https://public.tableau.com/views/NPMRDS-DataSummary-2020-Q1/DataQuality?:embed=y&amp;:showVizHome=no&amp;:display_count=yes&amp;:host_url=https%3A%2F%2Fpublic.tableau.com%2F&amp;:tabs=yes&amp;:toolbar=no&amp;:animate_transition=yes&amp;:display_static_image=no&amp;:display_spinner=yes&amp;:display_overlay=yes&amp;:display_count=yes&amp;publish=yes&amp;:loadOrderID=0",
            "NPMRDS Summary 2020 Q2":
              "https://public.tableau.com/views/NPMRDS-DataSummary-2020-Q2/DataQuality?:embed=y&amp;:showVizHome=no&amp;:display_count=yes&amp;:host_url=https%3A%2F%2Fpublic.tableau.com%2F&amp;:tabs=yes&amp;:toolbar=no&amp;:animate_transition=yes&amp;:display_static_image=no&amp;:display_spinner=yes&amp;:display_overlay=yes&amp;:display_count=yes&amp;publish=yes&amp;:loadOrderID=0",
            "NPMRDS Summary 2020 Q3":
              "https://public.tableau.com/views/NPMRDS-DataSummary-2020-Q3/Overview?:embed=y&amp;:showVizHome=no&amp;:display_count=yes&amp;:host_url=https%3A%2F%2Fpublic.tableau.com%2F&amp;:tabs=yes&amp;:toolbar=no&amp;:animate_transition=yes&amp;:display_static_image=no&amp;:display_spinner=yes&amp;:display_overlay=yes&amp;:display_count=yes&amp;publish=yes&amp;:loadOrderID=0",
            "NPMRDS Summary 2020 Q4":
              "https://public.tableau.com/views/NPMRDS-DataSummary-2020-Q4/Overview?:embed=y&amp;:showVizHome=no&amp;:display_count=yes&amp;:host_url=https%3A%2F%2Fpublic.tableau.com%2F&amp;:tabs=yes&amp;:toolbar=no&amp;:animate_transition=yes&amp;:display_static_image=no&amp;:display_spinner=yes&amp;:display_overlay=yes&amp;:display_count=yes&amp;publish=yes&amp;:loadOrderID=0",
          },
          Search: "search",
          UserListAccess: "userListAccess",
          "Quarterly Report": "quaterlyReport",
        },
      ],
      newLinks: [
        {
          KPIS: "underdevelopment", //"https://kpis.etalyc.com",
          C2C: "c2c",
          Profile: "users",
          Performance_Measures: {
            // "NPMRDS Summary 2019 Q1": "https://public.tableau.com/views/NPMRDS-DataSummary-Q1/Overview?:embed=y&amp;:showVizHome=no&amp;:display_count=yes&amp;:host_url=https%3A%2F%2Fpublic.tableau.com%2F&amp;:tabs=yes&amp;:toolbar=no&amp;:animate_transition=yes&amp;:display_static_image=no&amp;:display_spinner=yes&amp;:display_overlay=yes&amp;:display_count=yes&amp;publish=yes&amp;:loadOrderID=0",
            // "NPMRDS Summary 2019 Q2": "https://public.tableau.com/views/NPMRDS-DataSummary-Q2/Overview?:embed=y&amp;:showVizHome=no&amp;:display_count=yes&amp;:host_url=https%3A%2F%2Fpublic.tableau.com%2F&amp;:tabs=yes&amp;:toolbar=no&amp;:animate_transition=yes&amp;:display_static_image=no&amp;:display_spinner=yes&amp;:display_overlay=yes&amp;:display_count=yes&amp;publish=yes&amp;:loadOrderID=0",
            // "NPMRDS Summary 2019 Q3": "https://public.tableau.com/views/NPMRDS-DataSummary-Q3/Overview?:embed=y&amp;:showVizHome=no&amp;:display_count=yes&amp;:host_url=https%3A%2F%2Fpublic.tableau.com%2F&amp;:tabs=yes&amp;:toolbar=no&amp;:animate_transition=yes&amp;:display_static_image=no&amp;:display_spinner=yes&amp;:display_overlay=yes&amp;:display_count=yes&amp;publish=yes&amp;:loadOrderID=0",
            // "NPMRDS Summary 2019 Q4": "https://public.tableau.com/views/NPMRDS-DataSummary-Q4/Overview?:embed=y&amp;:showVizHome=no&amp;:display_count=yes&amp;:host_url=https%3A%2F%2Fpublic.tableau.com%2F&amp;:tabs=yes&amp;:toolbar=no&amp;:animate_transition=yes&amp;:display_static_image=no&amp;:display_spinner=yes&amp;:display_overlay=yes&amp;:display_count=yes&amp;publish=yes&amp;:loadOrderID=0",
            // "NPMRDS Summary 2020 Q1":
            //   "https://public.tableau.com/views/NPMRDS-DataSummary-2020-Q1/Overview?:embed=y&amp;:showVizHome=no&amp;:display_count=yes&amp;:host_url=https%3A%2F%2Fpublic.tableau.com%2F&amp;:tabs=yes&amp;:toolbar=no&amp;:animate_transition=yes&amp;:display_static_image=no&amp;:display_spinner=yes&amp;:display_overlay=yes&amp;:display_count=yes&amp;publish=yes&amp;:loadOrderID=0",
            // "NPMRDS Summary 2020 Q2":
            //   "https://public.tableau.com/views/NPMRDS-DataSummary-2020-Q2/Overview?:embed=y&amp;:showVizHome=no&amp;:display_count=yes&amp;:host_url=https%3A%2F%2Fpublic.tableau.com%2F&amp;:tabs=yes&amp;:toolbar=no&amp;:animate_transition=yes&amp;:display_static_image=no&amp;:display_spinner=yes&amp;:display_overlay=yes&amp;:display_count=yes&amp;publish=yes&amp;:loadOrderID=0",
            // "NPMRDS Summary 2020 Q3":
            //   "https://public.tableau.com/views/NPMRDS-DataSummary-2020-Q3/Overview?:embed=y&amp;:showVizHome=no&amp;:display_count=yes&amp;:host_url=https%3A%2F%2Fpublic.tableau.com%2F&amp;:tabs=yes&amp;:toolbar=no&amp;:animate_transition=yes&amp;:display_static_image=no&amp;:display_spinner=yes&amp;:display_overlay=yes&amp;:display_count=yes&amp;publish=yes&amp;:loadOrderID=0",
            // "NPMRDS Summary 2020 Q4":
            //   "https://public.tableau.com/views/NPMRDS-DataSummary-2020-Q4/Overview?:embed=y&amp;:showVizHome=no&amp;:display_count=yes&amp;:host_url=https%3A%2F%2Fpublic.tableau.com%2F&amp;:tabs=yes&amp;:toolbar=no&amp;:animate_transition=yes&amp;:display_static_image=no&amp;:display_spinner=yes&amp;:display_overlay=yes&amp;:display_count=yes&amp;publish=yes&amp;:loadOrderID=0",
            "NPMRDS Summary 2021 Q1":
              "https://public.tableau.com/views/NPMRDS-DataSummary-2021-Q1/Overview?:embed=y&amp;:showVizHome=no&amp;:display_count=yes&amp;:host_url=https%3A%2F%2Fpublic.tableau.com%2F&amp;:tabs=yes&amp;:toolbar=no&amp;:animate_transition=yes&amp;:display_static_image=no&amp;:display_spinner=yes&amp;:display_overlay=yes&amp;:display_count=yes&amp;publish=yes&amp;:loadOrderID=0",
            "NPMRDS Summary 2021 Q2":
              "https://public.tableau.com/views/NPMRDS-DataSummary-2021-Q2/Overview?:embed=y&amp;:showVizHome=no&amp;:display_count=yes&amp;:host_url=https%3A%2F%2Fpublic.tableau.com%2F&amp;:tabs=yes&amp;:toolbar=no&amp;:animate_transition=yes&amp;:display_static_image=no&amp;:display_spinner=yes&amp;:display_overlay=yes&amp;:display_count=yes&amp;publish=yes&amp;:loadOrderID=0",
            "NPMRDS Summary 2021 Q3":
              "https://public.tableau.com/views/NPMRDS-DataSummary-2021-Q3/Overview?:embed=y&amp;:showVizHome=no&amp;:display_count=yes&amp;:host_url=https%3A%2F%2Fpublic.tableau.com%2F&amp;:tabs=yes&amp;:toolbar=no&amp;:animate_transition=yes&amp;:display_static_image=no&amp;:display_spinner=yes&amp;:display_overlay=yes&amp;:display_count=yes&amp;publish=yes&amp;:loadOrderID=0",
            "NPMRDS Summary 2021 Q4":
              "https://public.tableau.com/views/NPMRDS-DataSummary-2021-Q4/Overview?:embed=y&amp;:showVizHome=no&amp;:display_count=yes&amp;:host_url=https%3A%2F%2Fpublic.tableau.com%2F&amp;:tabs=yes&amp;:toolbar=no&amp;:animate_transition=yes&amp;:display_static_image=no&amp;:display_spinner=yes&amp;:display_overlay=yes&amp;:display_count=yes&amp;publish=yes&amp;:loadOrderID=0",
            "NPMRDS Summary 2022 Q1":
              "https://public.tableau.com/views/NPMRDS-DataSummary-2022-Q1/Overview?:embed=y&amp;:showVizHome=no&amp;:display_count=yes&amp;:host_url=https%3A%2F%2Fpublic.tableau.com%2F&amp;:tabs=yes&amp;:toolbar=no&amp;:animate_transition=yes&amp;:display_static_image=no&amp;:display_spinner=yes&amp;:display_overlay=yes&amp;:display_count=yes&amp;publish=yes&amp;:loadOrderID=0",
            "NPMRDS Summary 2022 Q2":
              "https://public.tableau.com/views/NPMRDS-DataSummary-2022-Q2/Overview?:embed=y&amp;:showVizHome=no&amp;:display_count=yes&amp;:host_url=https%3A%2F%2Fpublic.tableau.com%2F&amp;:tabs=yes&amp;:toolbar=no&amp;:animate_transition=yes&amp;:display_static_image=no&amp;:display_spinner=yes&amp;:display_overlay=yes&amp;:display_count=yes&amp;publish=yes&amp;:loadOrderID=0",
            "NPMRDS Summary 2022 Q3":
              "https://public.tableau.com/views/NPMRDS-DataSummary-2022-Q3/Overview?:embed=y&amp;:showVizHome=no&amp;:display_count=yes&amp;:host_url=https%3A%2F%2Fpublic.tableau.com%2F&amp;:tabs=yes&amp;:toolbar=no&amp;:animate_transition=yes&amp;:display_static_image=no&amp;:display_spinner=yes&amp;:display_overlay=yes&amp;:display_count=yes&amp;publish=yes&amp;:loadOrderID=0",

            // https://public.tableau.com/app/profile/tingting1098/viz/NPMRDS-DataSummary-2022-Q3/Overview?publish=yes
          },
          Search: "search",
          UserListAccess: "userListAccess",
          "Quarterly Report": "quaterlyReport",
        },
      ],
      children: [
        { id: "Profile", icon: "HomeIcon", active: false },
        // { id: 'Performance Measures', icon: 'DnsRoundedIcon', active: true },
        // { id: 'KPIS', icon: 'FaCalendar', active: false },
        { id: "C2C", icon: "MdSignalCellular2Bar", active: false },
        // { id: 'Quarterly Report', icon: 'EqualizerIcon', active: false },
        { id: "Search", icon: "FilterIcon", active: false },
        { id: "UserListAccess", icon: "DashboardIcon", active: false },
      ],
    },
  },
};
