import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { BrowserRouter as Router, Route, Switch, Link, NavLink } from "react-router-dom";
import './NewWebsite.css'

const useStyles = makeStyles({
    root: {
        width: 500,
    },
});
function NewHeader() {
    const [isOpen, setIsOpen] = useState(true);

    const toggle = () => setIsOpen(!isOpen);
  
    return (
        <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-white shadow">
            <a className="navbar-brand" >
                <img src="/static/media/etalyc.320dbb84.jpg" width="130" alt="img" />
            </a>
            <button className="navbar-toggler" type="button" onClick={toggle} data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className={`${isOpen?'collapse navbar-collapse':'collapse-in navbar-collapse'}`} id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto">
                    <li className="nav-item ">
                        <NavLink to="/home" className="nav-link" activeClassName="active">Home</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/dashboard" className="nav-link" activeClassName="active">Dashboard</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/report" className="nav-link" activeClassName="active">Report</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/portal" className="nav-link" activeClassName="active">Portal</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/about" className="nav-link" activeClassName="active">About</NavLink>
                    </li>
                </ul>
                <ul className="form-inline my-2 my-lg-0 navbar-nav">
                    <li className="nav-item">
                        <NavLink to="/contact" className="nav-link" activeClassName="active">Contact</NavLink>
                    </li>

                </ul>
            </div>
            <div>

            </div>



        </nav>

    )
}

export default NewHeader
