import React, { useState, useEffect } from 'react';
import List from '@material-ui/core/List';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import EmailIcon from '@material-ui/icons/Email';
import ListItem from '@material-ui/core/ListItem';
import Backdrop from '@material-ui/core/Backdrop';
import PersonIcon from '@material-ui/icons/Person';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import Axios from 'axios';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import * as Constants from './../utilities/Constants';


const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '-25%',
    position: 'relative'
  },
}));

export default function Users() {
  const classes = useStyles();

  let token = localStorage.getItem('mcomp_token');
  const [lists, setlist] = useState([]);
  const [confirmEmailErrorMessage, setConfirmEmailErrorMessage] = useState(false);
  const [errorResponseMessage, setErrorResponseMessage] = useState('');
  useEffect(() => {
    Axios
      .get(Constants.NODE_API_BASE_URL + `/api/users/profile`, {
        headers: { 'authorization': token },
      })
      .then(res => {
        setlist(res['data']['users']);
      })
      .catch(err => {
        console.log("errorro", err)
      })
  }, [])

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const divStyle = {
    width: '100%'
  };
  const errorMsg = {
    color: 'red',
    textAlign: 'center',
    marginBottom: '20px',
  }



  return (
    <div>
      <List component="nav">
        <ListItem button>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary="User Name " />
          <ListItemText primary={lists.name} />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <EmailIcon />
          </ListItemIcon>
          <ListItemText primary="User Email" />
          <ListItemText primary={lists.email} />
        </ListItem>
        <ListItem button onClick={handleOpen}>
          <ListItemIcon>
            <LockOpenIcon />
          </ListItemIcon>
          <ListItemText primary="Change Password" />
          <Fab color="secondary" size="small" aria-label="edit">
            <EditIcon />
          </Fab>
        </ListItem>

      </List>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Change Password</h2>
            <h4 style={errorMsg}>{errorResponseMessage}</h4>
            <Formik
              initialValues={{ password: '', cpassword: '' }}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                let user = {
                  email: lists.email,
                  password: values.password
                }
                if (values.password === values.cpassword) {
                  Axios.post(Constants.NODE_API_BASE_URL + `/api/users/set-password`, user).then(res => {
                    toast.success("Your password has been successfully updated.");
                  }).catch(err => {
                    setSubmitting(false);
                    toast.error('Something went wrong!')
                  })
                }
                else {
                  console.log("Password and Confirm Password does not Matched!");
                  setErrorResponseMessage('Password and Confirm Password does not matched!')
                  setSubmitting(false);
                  toast.error()
                  setConfirmEmailErrorMessage(true)
                }
              }
              }

              validationSchema={Yup.object().shape({
                password: Yup.string()
                  .required('Password is Required'),
                cpassword: Yup.string()
                  .required('Confirm Password is Required'),
              })}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                } = props;
                return (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <TextField style={divStyle}
                          label="Password"
                          name="password"
                          variant="outlined"
                          className={classes.textField}
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.password && touched.password}
                          helperText={(errors.password && touched.password) && errors.password}
                          margin="normal"
                          onClick={() => { setErrorResponseMessage('') }}
                          type="password"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <TextField style={divStyle}
                          label="Confirm Password"
                          name="cpassword"
                          variant="outlined"
                          className={classes.textField}
                          value={values.cpassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.cpassword && touched.cpassword}
                          helperText={(errors.cpassword && touched.cpassword) && errors.cpassword}
                          margin="normal"
                          onClick={() => { setErrorResponseMessage('') }}
                          type="password"
                        />

                      </Grid>
                    </Grid>
                    <Grid style={{ 'marginTop': '30px' }}>
                      <Button style={{ 'width': '100px' }}
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={isSubmitting} >Save</Button>
                      <Button style={{ 'marginLeft': '20px', 'width': '100px' }}
                        type="button"
                        variant="contained"
                        color="secondary"
                        className={classes.submit}
                        onClick={handleClose}>Cancel </Button>
                    </Grid>
                    <ToastContainer />
                  </form>
                );
              }}
            </Formik>

          </div>
        </Fade>
      </Modal>


    </div>
  );
}