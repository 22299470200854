import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Formik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import * as Yup from 'yup';
import * as Constants from './../utilities/Constants';

function MadeWithLove() {
    return (
        <Typography variant="body2" color="textSecondary" align="center" style={{ paddingBottom: '25px' }}>
            {'Copyright © '}
            <Link color="inherit" to="https://etalyc.com/" style={{ color: '#5d6163' }}>
                Etalyc.com
        </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    container: {
        backgroundColor: '#ffffff',
        //padding: '5px',
    }
}));

export default function ForgotPassword() {
    const classes = useStyles();
    const [errorResponseMessage, setErrorResponseMessage] = useState('');
    const [emsg, setEmsg] = useState(false);
    const divStyle = {
        width: '100%'
    };
    const errorMsg = {
        color: 'red',
        textAlign: 'center'
    }
    const linkColor = {
        color: '#3f51b5',
    };
    const submitHeader = {
        boxShadow: '0px 0px 9px #978e8e',
        padding: '12px',
        background: '#5fa8d6',
        color: '#fff'
    }

    return (
        <Container component="main" maxWidth="xs" className={classes.container}>
            <CssBaseline />
            <div className={classes.paper}>
                <Grid item xs={12}>
                    <img src={require("../assets/etalyc.jpg")} width="400" alt="img" style={{ marginTop: '25px', marginBottom: '25px' }}></img>
                </Grid>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Forgot Password
        </Typography>
                <div className={classes.form} noValidate>
                    <h4 style={errorMsg}>{errorResponseMessage}</h4>
                    {emsg === true &&
                        <div className={classes.form}>
                            <h4 style={submitHeader}>
                                Your request has been submitted.<br></br> We will send you a password reset link to your email.
                                <br></br>
                            </h4>
                        </div>
                    } {emsg === false &&
                        <Formik
                            initialValues={{ email: '' }}
                            onSubmit={(values, { setSubmitting }) => {
                                setSubmitting(true);
                                let user = {
                                    email: values.email
                                }
                                axios.post(Constants.NODE_API_BASE_URL + `/api/users/forgotpassword`, user).then(res => {
                                    console.log("res", res);
                                    toast.success("Your request has been submitted.")
                                    setEmsg(true);
                                }).catch(err => {
                                    setSubmitting(false);
                                    if (err.response) {
                                        //Sometime error message is commin in errors object and sometimes without object
                                        // so we are handling it here 
                                        if (err.response.data.errors && err.response.data.errors['message']) {
                                            setErrorResponseMessage(err.response.data.errors['message']);
                                            toast.error(err.response.data.errors['message'])
                                        }

                                        if (err.response.data.error) {
                                            setErrorResponseMessage(err.response.data.error);
                                            toast.error(err.response.data.error)
                                        }
                                    }
                                })

                            }
                            }

                            validationSchema={Yup.object().shape({
                                email: Yup.string()
                                    .email()
                                    .required('Email is Required'),
                                // password: Yup.string()
                                //     .required('Password is Required'),
                            })}
                        >
                            {(props) => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    isSubmitting,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                } = props;
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12}>
                                                <TextField style={divStyle}
                                                    label="Email"
                                                    name="email"
                                                    variant="outlined"
                                                    className={classes.textField}
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={errors.email && touched.email}
                                                    helperText={(errors.email && touched.email) && errors.email}
                                                    margin="normal"
                                                    onClick={() => { setErrorResponseMessage('') }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            className={classes.submit}
                                            disabled={isSubmitting}
                                        >
                                            Submit
                                    </Button>
                                        <Grid container>
                                            <Grid item xs>
                                                {/* <Link to="/" variant="body2" style={linkColor}>
                                                Signin
                                            </Link> */}
                                            </Grid>
                                            <Grid item>
                                                <Link to="/signup" variant="body2" style={linkColor}>
                                                    {"Don't have an account? Sign Up"}
                                                </Link>
                                            </Grid>
                                        </Grid>


                                    </form>
                                );
                            }}
                        </Formik>
                    }
                    <ToastContainer />

                </div>
            </div>
            <Box mt={5}>
                <MadeWithLove />
            </Box>
        </Container>
    );
}