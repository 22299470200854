import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import ReactGA from "react-ga";
import NewHeader from "./NewHeader";
import Table from "@material-ui/core/Table";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import * as Constants from "./../../utilities/Constants";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import TableContainer from "@material-ui/core/TableContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  formControl: {
    margin: theme.spacing(2),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function NewReport() {
  const classes = useStyles();
  const [labelOne, setLabelOne] = React.useState();
  const [labelTwo, setLabelTwo] = React.useState();
  const [labelThree, setLabelThree] = React.useState();
  useEffect(() => {
    ReactGA.initialize(Constants.GOOGLE_ANALYTICS_KEY);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const GAEvent = (title, link) => {
    console.log("event", title);
    ReactGA.event({
      category: "Report: " + title,
      action: link,
    });
  };
  const [expanded, setExpanded] = React.useState();

  const handleChangeOne = (event) => {
    setLabelOne(event.target.value);
  };
  const handleChangeTwo = (event) => {
    setLabelTwo(event.target.value);
  };
  const handleChangeThree = (event) => {
    setLabelThree(event.target.value);
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <NewHeader />
        </Grid>
        <Grid item xs={10} style={{ margin: "auto" }}>
          <Grid container spacing={4} className="mb-100 c2cHeight">
            <Grid item xs={12} md={4}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Category</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  defaultValue=""
                  value={labelOne}
                  onChange={handleChangeOne}
                >
                  <MenuItem value={"all"}>All</MenuItem>
                  <MenuItem value={"report"}>Report</MenuItem>
                  <MenuItem value={"erroneous_sensor"}>
                    Erroneous Sensors
                  </MenuItem>
                  <MenuItem value={"documentation"}>Documentation</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {labelOne && labelOne !== "documentation" && labelOne !== "all" && (
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">Year</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={labelTwo}
                    onChange={handleChangeTwo}
                  >
                    <MenuItem value={"2019"}>2019</MenuItem>
                    <MenuItem value={"2020"}>2020</MenuItem>
                    <MenuItem value={"2021"}>2021</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}
            {labelOne && labelOne !== "documentation" && labelOne !== "all" && (
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">
                    Quaterly Report
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={labelThree}
                    onChange={handleChangeThree}
                  >
                    <MenuItem value={"q1"}>Q1</MenuItem>
                    <MenuItem value={"q2"}>Q2</MenuItem>
                    <MenuItem value={"q3"}>Q3</MenuItem>
                    <MenuItem value={"anual_report"}>Annual Report</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}

            {/* Docuiments */}
            <Grid
              item
              xs={12}
              style={{ paddingLeft: "28px", paddingRight: "0px" }}
            >
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Item</TableCell>
                      <TableCell align="center">Link</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  {labelOne && labelOne === "documentation" && (
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Delay Definition
                        </TableCell>
                        <TableCell align="center">
                          https://drive.google.com/file/d/156CnYVkFMMZW6cgopQKO-NYM09urdMAs/view?usp=sharing
                        </TableCell>
                        <TableCell align="center">
                          <Button variant="contained" color="primary">
                            <a
                              href={
                                "https://drive.google.com/file/d/156CnYVkFMMZW6cgopQKO-NYM09urdMAs/view?usp=sharing"
                              }
                              target="_blank"
                              onClick={(e) => {
                                GAEvent(
                                  "Delay Definition",
                                  "https://drive.google.com/file/d/156CnYVkFMMZW6cgopQKO-NYM09urdMAs/view?usp=sharing"
                                );
                              }}
                              style={{ color: "#fff", textDecoration: "none" }}
                            >
                              Open
                            </a>
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                  {/* 2021 Report */}
                  {labelOne &&
                    labelOne === "report" &&
                    labelTwo &&
                    labelTwo === "2021" &&
                    labelThree &&
                    labelThree === "q1" && (
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            2021 Q1
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            https://drive.google.com/file/d/1voiY-pitucmYtnyjawRNaMGAxh7--4dY/view
                          </TableCell>
                          <TableCell align="center">
                            <Button variant="contained" color="primary">
                              <a
                                href={
                                  "   https://drive.google.com/file/d/1voiY-pitucmYtnyjawRNaMGAxh7--4dY/view"
                                }
                                onClick={(e) => {
                                  GAEvent(
                                    "Report 2021 Q1",
                                    "https://drive.google.com/file/d/1voiY-pitucmYtnyjawRNaMGAxh7--4dY/view"
                                  );
                                }}
                                target="_blank"
                                style={{
                                  color: "#fff",
                                  textDecoration: "none",
                                }}
                              >
                                Open
                              </a>
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}

                  {labelOne &&
                    labelOne === "report" &&
                    labelTwo &&
                    labelTwo === "2021" &&
                    labelThree &&
                    labelThree === "q2" && (
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            2021 Q2
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            https://drive.google.com/file/d/1O5m9lBXxCkZHB1AZ4Yxk2XpNL6-1liib/view?usp=sharing
                          </TableCell>
                          <TableCell align="center">
                            <Button variant="contained" color="primary">
                              <a
                                href={
                                  "   https://drive.google.com/file/d/1O5m9lBXxCkZHB1AZ4Yxk2XpNL6-1liib/view?usp=sharing"
                                }
                                onClick={(e) => {
                                  GAEvent(
                                    "Report 2021 Q2",
                                    "https://drive.google.com/file/d/1O5m9lBXxCkZHB1AZ4Yxk2XpNL6-1liib/view?usp=sharing"
                                  );
                                }}
                                target="_blank"
                                style={{
                                  color: "#fff",
                                  textDecoration: "none",
                                }}
                              >
                                Open
                              </a>
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}

                  {/* 2021 q3 report */}
                  {labelOne &&
                    labelOne === "report" &&
                    labelTwo &&
                    labelTwo === "2021" &&
                    labelThree &&
                    labelThree === "q3" && (
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            2021 Q3
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            https://drive.google.com/file/d/1ZOORdpaXzeTZ_wAddTJE8_w55lnlhBEG/view?usp=sharing
                          </TableCell>
                          <TableCell align="center">
                            <Button variant="contained" color="primary">
                              <a
                                href={
                                  "   https://drive.google.com/file/d/1ZOORdpaXzeTZ_wAddTJE8_w55lnlhBEG/view?usp=sharing"
                                }
                                onClick={(e) => {
                                  GAEvent(
                                    "Report 2021 Q3",
                                    "https://drive.google.com/file/d/1ZOORdpaXzeTZ_wAddTJE8_w55lnlhBEG/view?usp=sharing"
                                  );
                                }}
                                target="_blank"
                                style={{
                                  color: "#fff",
                                  textDecoration: "none",
                                }}
                              >
                                Open
                              </a>
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}

                  {/* 2021 Annual Report */}

                  {labelOne &&
                    labelOne === "report" &&
                    labelTwo &&
                    labelTwo === "2021" &&
                    labelThree &&
                    labelThree === "anual_report" && (
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            2021 Annual
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            https://drive.google.com/file/d/1InQ9dfNH4gihQiHo2dbwCkOW5KjLwiBJ/view?usp=sharing
                          </TableCell>
                          <TableCell align="center">
                            <Button variant="contained" color="primary">
                              <a
                                href={
                                  "https://drive.google.com/file/d/1InQ9dfNH4gihQiHo2dbwCkOW5KjLwiBJ/view?usp=sharing"
                                }
                                onClick={(e) => {
                                  GAEvent(
                                    "2021 Annual Report ",
                                    " https://drive.google.com/file/d/1InQ9dfNH4gihQiHo2dbwCkOW5KjLwiBJ/view?usp=sharing"
                                  );
                                }}
                                target="_blank"
                                style={{
                                  color: "#fff",
                                  textDecoration: "none",
                                }}
                              >
                                Open
                              </a>
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}

                  {labelOne &&
                    labelOne === "report" &&
                    labelTwo &&
                    labelTwo === "2020" &&
                    labelThree &&
                    labelThree === "q1" && (
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            2020 Q1
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            https://drive.google.com/open?id=1oU1wP8zJ0XyhrvNSiWWRVGnU77lxAb1W
                          </TableCell>
                          <TableCell align="center">
                            <Button variant="contained" color="primary">
                              <a
                                href={
                                  "   https://drive.google.com/open?id=1oU1wP8zJ0XyhrvNSiWWRVGnU77lxAb1W"
                                }
                                onClick={(e) => {
                                  GAEvent(
                                    "Report 2020 Q1",
                                    "https://drive.google.com/open?id=1oU1wP8zJ0XyhrvNSiWWRVGnU77lxAb1W"
                                  );
                                }}
                                target="_blank"
                                style={{
                                  color: "#fff",
                                  textDecoration: "none",
                                }}
                              >
                                Open
                              </a>
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  {labelOne &&
                    labelOne === "report" &&
                    labelTwo &&
                    labelTwo === "2020" &&
                    labelThree &&
                    labelThree === "q2" && (
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            2020 Q2
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            https://drive.google.com/file/d/1bMODfmIPZ-GF5fTMGvT8AYPoEZYDE7ku/view
                          </TableCell>
                          <TableCell align="center">
                            <Button variant="contained" color="primary">
                              <a
                                href={
                                  "https://drive.google.com/file/d/1bMODfmIPZ-GF5fTMGvT8AYPoEZYDE7ku/view"
                                }
                                target="_blank"
                                style={{
                                  color: "#fff",
                                  textDecoration: "none",
                                }}
                              >
                                Open
                              </a>
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  {labelOne &&
                    labelOne === "report" &&
                    labelTwo &&
                    labelTwo === "2020" &&
                    labelThree &&
                    labelThree === "q3" && (
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            2020 Q3
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            https://drive.google.com/file/d/1_EWyrijk-lQK6ZkCrGefryh4CEABpBuN/view?usp=sharing
                          </TableCell>
                          <TableCell align="center">
                            <Button variant="contained" color="primary">
                              <a
                                href={
                                  "https://drive.google.com/file/d/1_EWyrijk-lQK6ZkCrGefryh4CEABpBuN/view?usp=sharing"
                                }
                                target="_blank"
                                style={{
                                  color: "#fff",
                                  textDecoration: "none",
                                }}
                              >
                                Open
                              </a>
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  {/* {labelOne && labelOne === 'report' && labelTwo && labelTwo === '2020' && labelThree && labelThree === 'q4' &&
                                        <TableBody>
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    2020 Q4
                                                </TableCell>
                                                <TableCell align="center">   https://drive.google.com/file/d/1_EWyrijk-lQK6ZkCrGefryh4CEABpBuN/view?usp=sharing</TableCell>
                                                <TableCell align="center">
                                                    <Button variant="contained" color="primary">
                                                        <a href={'https://drive.google.com/file/d/1_EWyrijk-lQK6ZkCrGefryh4CEABpBuN/view?usp=sharing'} target="_blank" style={{ 'color': '#fff', 'textDecoration': 'none' }}>Open</a>
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    } */}
                  {labelOne &&
                    labelOne === "report" &&
                    labelTwo &&
                    labelTwo === "2020" &&
                    labelThree &&
                    labelThree === "anual_report" && (
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            2020 Annual Report
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            https://drive.google.com/file/d/1fPt7wRQB7mgbRMYzQTaLLkobX5XBN_kN/view?usp=sharing
                          </TableCell>
                          <TableCell align="center">
                            <Button variant="contained" color="primary">
                              <a
                                href={
                                  "  https://drive.google.com/file/d/1fPt7wRQB7mgbRMYzQTaLLkobX5XBN_kN/view?usp=sharing"
                                }
                                onClick={(e) => {
                                  GAEvent(
                                    "2020 Annual Report",
                                    "https://drive.google.com/file/d/1fPt7wRQB7mgbRMYzQTaLLkobX5XBN_kN/view?usp=sharing"
                                  );
                                }}
                                target="_blank"
                                style={{
                                  color: "#fff",
                                  textDecoration: "none",
                                }}
                              >
                                Open
                              </a>
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}

                  {labelOne &&
                    labelOne === "report" &&
                    labelTwo &&
                    labelTwo === "2019" &&
                    labelThree &&
                    labelThree === "q1" && (
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            2019 Q1
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            https://drive.google.com/open?id=1xeRbCama7kWwVZ_R8dTeOlIyRlS1X3jL
                          </TableCell>
                          <TableCell align="center">
                            <Button variant="contained" color="primary">
                              <a
                                href={
                                  " https://drive.google.com/open?id=1xeRbCama7kWwVZ_R8dTeOlIyRlS1X3jL"
                                }
                                onClick={(e) => {
                                  GAEvent(
                                    "Report 2019 Q1",
                                    "https://drive.google.com/open?id=1xeRbCama7kWwVZ_R8dTeOlIyRlS1X3jL"
                                  );
                                }}
                                target="_blank"
                                style={{
                                  color: "#fff",
                                  textDecoration: "none",
                                }}
                              >
                                Open
                              </a>
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  {labelOne &&
                    labelOne === "report" &&
                    labelTwo &&
                    labelTwo === "2019" &&
                    labelThree &&
                    labelThree === "q2" && (
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            2019 Q2
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            https://drive.google.com/open?id=1nu9_zrAiye_RBn5QXJvoB2UMrIxZkpNd
                          </TableCell>
                          <TableCell align="center">
                            <Button variant="contained" color="primary">
                              <a
                                href={
                                  "  https://drive.google.com/open?id=1nu9_zrAiye_RBn5QXJvoB2UMrIxZkpNd"
                                }
                                onClick={(e) => {
                                  GAEvent(
                                    "Report 2019 Q2",
                                    "https://drive.google.com/open?id=1nu9_zrAiye_RBn5QXJvoB2UMrIxZkpNd"
                                  );
                                }}
                                target="_blank"
                                style={{
                                  color: "#fff",
                                  textDecoration: "none",
                                }}
                              >
                                Open
                              </a>
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}

                  {labelOne &&
                    labelOne === "report" &&
                    labelTwo &&
                    labelTwo === "2019" &&
                    labelThree &&
                    labelThree === "q3" && (
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            2019 Q3
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            https://drive.google.com/open?id=1RnoPal-ezReqyixmHFd0CQRbc9UZZY-9
                          </TableCell>
                          <TableCell align="center">
                            <Button variant="contained" color="primary">
                              <a
                                href={
                                  "  https://drive.google.com/open?id=1RnoPal-ezReqyixmHFd0CQRbc9UZZY-9"
                                }
                                onClick={(e) => {
                                  GAEvent(
                                    "Report 2019 Q3",
                                    "https://drive.google.com/open?id=1RnoPal-ezReqyixmHFd0CQRbc9UZZY-9"
                                  );
                                }}
                                target="_blank"
                                style={{
                                  color: "#fff",
                                  textDecoration: "none",
                                }}
                              >
                                Open
                              </a>
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}

                  {labelOne &&
                    labelOne === "report" &&
                    labelTwo &&
                    labelTwo === "2019" &&
                    labelThree &&
                    labelThree === "anual_report" && (
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            2019 Annual Report
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            https://drive.google.com/open?id=15nyo_BzVPQuDeNGOaasEcK8jMAVE-Pam
                          </TableCell>
                          <TableCell align="center">
                            <Button variant="contained" color="primary">
                              <a
                                href={
                                  "  https://drive.google.com/open?id=15nyo_BzVPQuDeNGOaasEcK8jMAVE-Pam"
                                }
                                onClick={(e) => {
                                  GAEvent(
                                    "2019 Annual Report",
                                    "https://drive.google.com/open?id=15nyo_BzVPQuDeNGOaasEcK8jMAVE-Pam"
                                  );
                                }}
                                target="_blank"
                                style={{
                                  color: "#fff",
                                  textDecoration: "none",
                                }}
                              >
                                Open
                              </a>
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}

                  {/* gggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggg */}

                  {labelOne &&
                    labelOne === "erroneous_sensor" &&
                    labelTwo &&
                    labelTwo === "2019" &&
                    labelThree &&
                    labelThree === "q1" && (
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            2019 Q1 - IA Sensors
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            https://drive.google.com/open?id=13BZmroQCXye5_P1GDdTJ5yMPQLiSEta_
                          </TableCell>
                          <TableCell align="center">
                            <Button variant="contained" color="primary">
                              <a
                                href={
                                  " https://drive.google.com/open?id=13BZmroQCXye5_P1GDdTJ5yMPQLiSEta_"
                                }
                                onClick={(e) => {
                                  GAEvent(
                                    " 2019 Q1 - IA Sensors",
                                    "https://drive.google.com/open?id=13BZmroQCXye5_P1GDdTJ5yMPQLiSEta_"
                                  );
                                }}
                                target="_blank"
                                style={{
                                  color: "#fff",
                                  textDecoration: "none",
                                }}
                              >
                                Open
                              </a>
                            </Button>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            2019 Q1 - KS Sensors
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            https://drive.google.com/open?id=1iM_qIpytkMiI8RAEJwWmb3VlqnKQhdtL
                          </TableCell>
                          <TableCell align="center">
                            <Button variant="contained" color="primary">
                              <a
                                href={
                                  "https://drive.google.com/open?id=1iM_qIpytkMiI8RAEJwWmb3VlqnKQhdtL"
                                }
                                onClick={(e) => {
                                  GAEvent(
                                    " 2019 Q1 - KS Sensors",
                                    "https://drive.google.com/open?id=1iM_qIpytkMiI8RAEJwWmb3VlqnKQhdtL"
                                  );
                                }}
                                target="_blank"
                                style={{
                                  color: "#fff",
                                  textDecoration: "none",
                                }}
                              >
                                Open
                              </a>
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  {labelOne &&
                    labelOne === "erroneous_sensor" &&
                    labelTwo &&
                    labelTwo === "2019" &&
                    labelThree &&
                    labelThree === "q2" && (
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            2019 Q2 - IA Sensors
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            https://drive.google.com/open?id=1ZtyOtNtp1w5qF0hWdl3he5orW1b3GKv5
                          </TableCell>
                          <TableCell align="center">
                            <Button variant="contained" color="primary">
                              <a
                                href={
                                  " https://drive.google.com/open?id=1ZtyOtNtp1w5qF0hWdl3he5orW1b3GKv5"
                                }
                                onClick={(e) => {
                                  GAEvent(
                                    "2019 Q2 - IA Sensors",
                                    " https://drive.google.com/open?id=1ZtyOtNtp1w5qF0hWdl3he5orW1b3GKv5"
                                  );
                                }}
                                target="_blank"
                                style={{
                                  color: "#fff",
                                  textDecoration: "none",
                                }}
                              >
                                Open
                              </a>
                            </Button>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            2019 Q2 - KS Sensors
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            https://drive.google.com/open?id=1mwyx3HV0MrrScn_YnoH2dJOnYU625BJq
                          </TableCell>
                          <TableCell align="center">
                            <Button variant="contained" color="primary">
                              <a
                                href={
                                  "  https://drive.google.com/open?id=1mwyx3HV0MrrScn_YnoH2dJOnYU625BJq"
                                }
                                onClick={(e) => {
                                  GAEvent(
                                    " 2019 Q2 - KS Sensors",
                                    " https://drive.google.com/open?id=1mwyx3HV0MrrScn_YnoH2dJOnYU625BJq"
                                  );
                                }}
                                target="_blank"
                                style={{
                                  color: "#fff",
                                  textDecoration: "none",
                                }}
                              >
                                Open
                              </a>
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  {labelOne &&
                    labelOne === "erroneous_sensor" &&
                    labelTwo &&
                    labelTwo === "2019" &&
                    labelThree &&
                    labelThree === "q3" && (
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            2019 Q3 - IA Sensors
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            https://drive.google.com/open?id=1SZhieF2htbcHZUVbf3g5A82Qcmlm0hvh
                          </TableCell>
                          <TableCell align="center">
                            <Button variant="contained" color="primary">
                              <a
                                href={
                                  "  https://drive.google.com/open?id=1SZhieF2htbcHZUVbf3g5A82Qcmlm0hvh"
                                }
                                onClick={(e) => {
                                  GAEvent(
                                    " 2019 Q3 - IA Sensors",
                                    " https://drive.google.com/open?id=1SZhieF2htbcHZUVbf3g5A82Qcmlm0hvh"
                                  );
                                }}
                                target="_blank"
                                style={{
                                  color: "#fff",
                                  textDecoration: "none",
                                }}
                              >
                                Open
                              </a>
                            </Button>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            2019 Q3 - KS Sensors
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            https://drive.google.com/open?id=1mbMtHB1Ru223zUm2HbOVyykZ0MoS7Y07
                          </TableCell>
                          <TableCell align="center">
                            <Button variant="contained" color="primary">
                              <a
                                href={
                                  "  https://drive.google.com/open?id=1mbMtHB1Ru223zUm2HbOVyykZ0MoS7Y07"
                                }
                                onClick={(e) => {
                                  GAEvent(
                                    " 2019 Q3 - KS Sensors",
                                    " https://drive.google.com/open?id=1mbMtHB1Ru223zUm2HbOVyykZ0MoS7Y07"
                                  );
                                }}
                                target="_blank"
                                style={{
                                  color: "#fff",
                                  textDecoration: "none",
                                }}
                              >
                                Open
                              </a>
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  {labelOne &&
                    labelOne === "erroneous_sensor" &&
                    labelTwo &&
                    labelTwo === "2019" &&
                    labelThree &&
                    labelThree === "anual_report" && (
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            2019 Annual - IA Sensors
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            https://drive.google.com/open?id=1xJCJ181hMvbqn4Tw2ty2DuMaFIKksTcl
                          </TableCell>
                          <TableCell align="center">
                            <Button variant="contained" color="primary">
                              <a
                                href={
                                  "  https://drive.google.com/open?id=1xJCJ181hMvbqn4Tw2ty2DuMaFIKksTcl"
                                }
                                onClick={(e) => {
                                  GAEvent(
                                    " 2019 Annual - IA Sensors",
                                    " https://drive.google.com/open?id=1xJCJ181hMvbqn4Tw2ty2DuMaFIKksTcl"
                                  );
                                }}
                                target="_blank"
                                style={{
                                  color: "#fff",
                                  textDecoration: "none",
                                }}
                              >
                                Open
                              </a>
                            </Button>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            2019 Annual - KS Sensors
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            https://drive.google.com/open?id=159-KQ5j38dIo7-zHWNXnf0clY_6SZHyC
                          </TableCell>
                          <TableCell align="center">
                            <Button variant="contained" color="primary">
                              <a
                                href={
                                  " https://drive.google.com/open?id=159-KQ5j38dIo7-zHWNXnf0clY_6SZHyC"
                                }
                                onClick={(e) => {
                                  GAEvent(
                                    " 2019 Annual - KS Sensors",
                                    "  https://drive.google.com/open?id=159-KQ5j38dIo7-zHWNXnf0clY_6SZHyC"
                                  );
                                }}
                                target="_blank"
                                style={{
                                  color: "#fff",
                                  textDecoration: "none",
                                }}
                              >
                                Open
                              </a>
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}

                  {labelOne &&
                    labelOne === "erroneous_sensor" &&
                    labelTwo &&
                    labelTwo === "2020" &&
                    labelThree &&
                    labelThree === "q1" && (
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            2020 Q1 - IA Sensors
                          </TableCell>
                          <TableCell align="center">
                            https://drive.google.com/open?id=1dR6FnTATYD5E-Pd6xl8k1OPgCyTC-jE3
                          </TableCell>
                          <TableCell align="center">
                            <Button variant="contained" color="primary">
                              <a
                                href={
                                  "https://drive.google.com/open?id=1dR6FnTATYD5E-Pd6xl8k1OPgCyTC-jE3"
                                }
                                onClick={(e) => {
                                  GAEvent(
                                    " 2020 Q1 - IA Sensors",
                                    "  https://drive.google.com/open?id=1dR6FnTATYD5E-Pd6xl8k1OPgCyTC-jE3"
                                  );
                                }}
                                target="_blank"
                                style={{
                                  color: "#fff",
                                  textDecoration: "none",
                                }}
                              >
                                Open
                              </a>
                            </Button>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            2020 Q1 - KS Sensors
                          </TableCell>
                          <TableCell align="center">
                            https://drive.google.com/open?id=1kpKpn6pQdtNqr3ufKNP4hvaPKfNhRsh3
                          </TableCell>
                          <TableCell align="center">
                            <Button variant="contained" color="primary">
                              <a
                                href={
                                  "https://drive.google.com/open?id=1kpKpn6pQdtNqr3ufKNP4hvaPKfNhRsh3"
                                }
                                onClick={(e) => {
                                  GAEvent(
                                    " 2020 Q1 - KS Sensors",
                                    "  https://drive.google.com/open?id=1kpKpn6pQdtNqr3ufKNP4hvaPKfNhRsh3"
                                  );
                                }}
                                target="_blank"
                                style={{
                                  color: "#fff",
                                  textDecoration: "none",
                                }}
                              >
                                Open
                              </a>
                            </Button>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            2020 Q1 - MO Sensors
                          </TableCell>
                          <TableCell align="center">
                            https://drive.google.com/open?id=1vJ3OfKkcxt0Ly3ihtrDDLmdt-9EgDg3n
                          </TableCell>
                          <TableCell align="center">
                            <Button variant="contained" color="primary">
                              <a
                                href={
                                  "https://drive.google.com/open?id=1vJ3OfKkcxt0Ly3ihtrDDLmdt-9EgDg3n"
                                }
                                onClick={(e) => {
                                  GAEvent(
                                    "2020 Q1 - MO Sensors",
                                    "  https://drive.google.com/open?id=1vJ3OfKkcxt0Ly3ihtrDDLmdt-9EgDg3n"
                                  );
                                }}
                                target="_blank"
                                style={{
                                  color: "#fff",
                                  textDecoration: "none",
                                }}
                              >
                                Open
                              </a>
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  {labelOne &&
                    labelOne === "erroneous_sensor" &&
                    labelTwo &&
                    labelTwo === "2020" &&
                    labelThree &&
                    labelThree === "q2" && (
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            2020 Q2 - IA Sensors
                          </TableCell>
                          <TableCell align="center">
                            https://drive.google.com/file/d/1z3EqeP9SaH67BgyvjidJvkTpRi7308l2/view?usp=sharing
                          </TableCell>
                          <TableCell align="center">
                            <Button variant="contained" color="primary">
                              <a
                                href={
                                  "https://drive.google.com/file/d/1z3EqeP9SaH67BgyvjidJvkTpRi7308l2/view?usp=sharing"
                                }
                                onClick={(e) => {
                                  GAEvent(
                                    " 2020 Q1 - IA Sensors",
                                    "  https://drive.google.com/file/d/1z3EqeP9SaH67BgyvjidJvkTpRi7308l2/view?usp=sharing"
                                  );
                                }}
                                target="_blank"
                                style={{
                                  color: "#fff",
                                  textDecoration: "none",
                                }}
                              >
                                Open
                              </a>
                            </Button>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            2020 Q2 - KS Sensors
                          </TableCell>
                          <TableCell align="center">
                            https://drive.google.com/file/d/1Erk3VL9eBAqj9HBQ767zaHbihYEW41KM/view?usp=sharing
                          </TableCell>
                          <TableCell align="center">
                            <Button variant="contained" color="primary">
                              <a
                                href={
                                  "https://drive.google.com/file/d/1Erk3VL9eBAqj9HBQ767zaHbihYEW41KM/view?usp=sharing"
                                }
                                onClick={(e) => {
                                  GAEvent(
                                    " 2020 Q1 - KS Sensors",
                                    "  https://drive.google.com/file/d/1Erk3VL9eBAqj9HBQ767zaHbihYEW41KM/view?usp=sharing"
                                  );
                                }}
                                target="_blank"
                                style={{
                                  color: "#fff",
                                  textDecoration: "none",
                                }}
                              >
                                Open
                              </a>
                            </Button>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            2020 Q2 - MO Sensors
                          </TableCell>
                          <TableCell align="center">
                            https://drive.google.com/file/d/1F_RvuAeusFNjO3kK1jR0SnzdHMW2cxR9/view?usp=sharing
                          </TableCell>
                          <TableCell align="center">
                            <Button variant="contained" color="primary">
                              <a
                                href={
                                  "https://drive.google.com/file/d/1F_RvuAeusFNjO3kK1jR0SnzdHMW2cxR9/view?usp=sharing"
                                }
                                onClick={(e) => {
                                  GAEvent(
                                    "2020 Q1 - MO Sensors",
                                    "  https://drive.google.com/file/d/1F_RvuAeusFNjO3kK1jR0SnzdHMW2cxR9/view?usp=sharing"
                                  );
                                }}
                                target="_blank"
                                style={{
                                  color: "#fff",
                                  textDecoration: "none",
                                }}
                              >
                                Open
                              </a>
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  {labelOne &&
                    labelOne === "erroneous_sensor" &&
                    labelTwo &&
                    labelTwo === "2020" &&
                    labelThree &&
                    labelThree === "q3" && (
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            2020 Q3 - IA Sensors
                          </TableCell>
                          <TableCell align="center">
                            https://drive.google.com/file/d/1sqzX8eed2vgUQoXF7pIe8EMBk3or0bYU/view?usp=sharing
                          </TableCell>
                          <TableCell align="center">
                            <Button variant="contained" color="primary">
                              <a
                                href={
                                  "https://drive.google.com/file/d/1sqzX8eed2vgUQoXF7pIe8EMBk3or0bYU/view?usp=sharing"
                                }
                                onClick={(e) => {
                                  GAEvent(
                                    " 2020 Q1 - IA Sensors",
                                    "  https://drive.google.com/file/d/1sqzX8eed2vgUQoXF7pIe8EMBk3or0bYU/view?usp=sharing"
                                  );
                                }}
                                target="_blank"
                                style={{
                                  color: "#fff",
                                  textDecoration: "none",
                                }}
                              >
                                Open
                              </a>
                            </Button>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            2020 Q3 - KS Sensors
                          </TableCell>
                          <TableCell align="center">
                            https://drive.google.com/file/d/1Pho56RBRBQ8CRJGxWNehg78TlorxjidK/view?usp=sharing
                          </TableCell>
                          <TableCell align="center">
                            <Button variant="contained" color="primary">
                              <a
                                href={
                                  "https://drive.google.com/file/d/1Pho56RBRBQ8CRJGxWNehg78TlorxjidK/view?usp=sharing"
                                }
                                onClick={(e) => {
                                  GAEvent(
                                    " 2020 Q1 - KS Sensors",
                                    "  https://drive.google.com/file/d/1Pho56RBRBQ8CRJGxWNehg78TlorxjidK/view?usp=sharing"
                                  );
                                }}
                                target="_blank"
                                style={{
                                  color: "#fff",
                                  textDecoration: "none",
                                }}
                              >
                                Open
                              </a>
                            </Button>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            2020 Q3 - MO Sensors
                          </TableCell>
                          <TableCell align="center">
                            https://drive.google.com/file/d/10QA1AzXmLffs3Vh3MwA9zYWm6613DLHR/view?usp=sharing
                          </TableCell>
                          <TableCell align="center">
                            <Button variant="contained" color="primary">
                              <a
                                href={
                                  "https://drive.google.com/file/d/10QA1AzXmLffs3Vh3MwA9zYWm6613DLHR/view?usp=sharing"
                                }
                                onClick={(e) => {
                                  GAEvent(
                                    "2020 Q1 - MO Sensors",
                                    "  https://drive.google.com/file/d/10QA1AzXmLffs3Vh3MwA9zYWm6613DLHR/view?usp=sharing"
                                  );
                                }}
                                target="_blank"
                                style={{
                                  color: "#fff",
                                  textDecoration: "none",
                                }}
                              >
                                Open
                              </a>
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  {/* 2021  */}

                  {/* 2021 q2 */}

                  {labelOne &&
                    labelOne === "erroneous_sensor" &&
                    labelTwo &&
                    labelTwo === "2021" &&
                    labelThree &&
                    labelThree === "q2" && (
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            2021 Q2 - IA Sensors
                          </TableCell>
                          <TableCell align="center">
                            https://docs.google.com/spreadsheets/d/15yV5KULkcqSpSHtEWOfqBl4IhDuIe0x6/edit?usp=sharing&ouid=114128508673891658631&rtpof=true&sd=true
                          </TableCell>
                          <TableCell align="center">
                            <Button variant="contained" color="primary">
                              <a
                                href={
                                  "https://docs.google.com/spreadsheets/d/15yV5KULkcqSpSHtEWOfqBl4IhDuIe0x6/edit?usp=sharing&ouid=114128508673891658631&rtpof=true&sd=true"
                                }
                                onClick={(e) => {
                                  GAEvent(
                                    " 2020 Q2 - IA Sensors",
                                    " https://docs.google.com/spreadsheets/d/15yV5KULkcqSpSHtEWOfqBl4IhDuIe0x6/edit?usp=sharing&ouid=114128508673891658631&rtpof=true&sd=true"
                                  );
                                }}
                                target="_blank"
                                style={{
                                  color: "#fff",
                                  textDecoration: "none",
                                }}
                              >
                                Open
                              </a>
                            </Button>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            2021 Q2 - KS Sensors
                          </TableCell>
                          <TableCell align="center">
                            https://docs.google.com/spreadsheets/d/1k8Cnkhmn33Ye1a8IIiR2CCiRzbwRb2vP/edit?usp=sharing&ouid=114128508673891658631&rtpof=true&sd=true
                          </TableCell>
                          <TableCell align="center">
                            <Button variant="contained" color="primary">
                              <a
                                href={
                                  "https://docs.google.com/spreadsheets/d/1k8Cnkhmn33Ye1a8IIiR2CCiRzbwRb2vP/edit?usp=sharing&ouid=114128508673891658631&rtpof=true&sd=true"
                                }
                                onClick={(e) => {
                                  GAEvent(
                                    " 2020 Q2 - KS Sensors",
                                    "https://docs.google.com/spreadsheets/d/1k8Cnkhmn33Ye1a8IIiR2CCiRzbwRb2vP/edit?usp=sharing&ouid=114128508673891658631&rtpof=true&sd=true"
                                  );
                                }}
                                target="_blank"
                                style={{
                                  color: "#fff",
                                  textDecoration: "none",
                                }}
                              >
                                Open
                              </a>
                            </Button>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            2021 Q2 - MO Sensors
                          </TableCell>
                          <TableCell align="center">
                            https://docs.google.com/spreadsheets/d/1dvUnfuP2WyfvgYuxMsZ7s3BGgYAolon4/edit?usp=sharing&ouid=114128508673891658631&rtpof=true&sd=true
                          </TableCell>
                          <TableCell align="center">
                            <Button variant="contained" color="primary">
                              <a
                                href={
                                  "https://docs.google.com/spreadsheets/d/1dvUnfuP2WyfvgYuxMsZ7s3BGgYAolon4/edit?usp=sharing&ouid=114128508673891658631&rtpof=true&sd=true"
                                }
                                onClick={(e) => {
                                  GAEvent(
                                    " 2020 Q2 - MO Sensors",
                                    "https://docs.google.com/spreadsheets/d/1dvUnfuP2WyfvgYuxMsZ7s3BGgYAolon4/edit?usp=sharing&ouid=114128508673891658631&rtpof=true&sd=true"
                                  );
                                }}
                                target="_blank"
                                style={{
                                  color: "#fff",
                                  textDecoration: "none",
                                }}
                              >
                                Open
                              </a>
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}

                  {/* 2021 End */}
                  {labelOne &&
                    labelOne === "erroneous_sensor" &&
                    labelTwo &&
                    labelTwo === "2020" &&
                    labelThree &&
                    labelThree === "anual_report" && (
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            2020 Annual Report - IA Sensors
                          </TableCell>
                          <TableCell align="center">
                            https://drive.google.com/file/d/1UoCLrnCUP3j8GgZXLvcKCAYm5xqtgKf1/view?usp=sharing
                          </TableCell>
                          <TableCell align="center">
                            <Button variant="contained" color="primary">
                              <a
                                href={
                                  "https://drive.google.com/file/d/1UoCLrnCUP3j8GgZXLvcKCAYm5xqtgKf1/view?usp=sharing"
                                }
                                onClick={(e) => {
                                  GAEvent(
                                    "  2020 Annual Report - IA Sensors",
                                    "  https://drive.google.com/file/d/1UoCLrnCUP3j8GgZXLvcKCAYm5xqtgKf1/view?usp=sharing"
                                  );
                                }}
                                target="_blank"
                                style={{
                                  color: "#fff",
                                  textDecoration: "none",
                                }}
                              >
                                Open
                              </a>
                            </Button>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            2020 Annual Report - KS Sensors
                          </TableCell>
                          <TableCell align="center">
                            https://drive.google.com/file/d/1GcPkYifeQzSNmy13t72Ftd2HEppNnu-5/view?usp=sharing
                          </TableCell>
                          <TableCell align="center">
                            <Button variant="contained" color="primary">
                              <a
                                href={
                                  "https://drive.google.com/file/d/1GcPkYifeQzSNmy13t72Ftd2HEppNnu-5/view?usp=sharing"
                                }
                                onClick={(e) => {
                                  GAEvent(
                                    "  2020 Annual Report - KS Sensors",
                                    "  https://drive.google.com/file/d/1GcPkYifeQzSNmy13t72Ftd2HEppNnu-5/view?usp=sharing"
                                  );
                                }}
                                target="_blank"
                                style={{
                                  color: "#fff",
                                  textDecoration: "none",
                                }}
                              >
                                Open
                              </a>
                            </Button>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            2020 Annual Report - MO Sensors
                          </TableCell>
                          <TableCell align="center">
                            https://drive.google.com/file/d/1piouIzopkV8NgO04EJBwTpLNmgkX2KVg/view?usp=sharing
                          </TableCell>
                          <TableCell align="center">
                            <Button variant="contained" color="primary">
                              <a
                                href={
                                  "https://drive.google.com/file/d/1piouIzopkV8NgO04EJBwTpLNmgkX2KVg/view?usp=sharing"
                                }
                                onClick={(e) => {
                                  GAEvent(
                                    " 2020 Annual Report - MO Sensors",
                                    "  https://drive.google.com/file/d/1piouIzopkV8NgO04EJBwTpLNmgkX2KVg/view?usp=sharing"
                                  );
                                }}
                                target="_blank"
                                style={{
                                  color: "#fff",
                                  textDecoration: "none",
                                }}
                              >
                                Open
                              </a>
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}

                  {/*(((((((((((((((((((  All Report Data  ))))))))))))))))))  )))))))))))))))))))) */}
                  {labelOne && labelOne === "all" && (
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          2019 Q1
                        </TableCell>
                        <TableCell align="center">
                          {" "}
                          https://drive.google.com/open?id=1xeRbCama7kWwVZ_R8dTeOlIyRlS1X3jL
                        </TableCell>
                        <TableCell align="center">
                          <Button variant="contained" color="primary">
                            <a
                              href={
                                " https://drive.google.com/open?id=1xeRbCama7kWwVZ_R8dTeOlIyRlS1X3jL"
                              }
                              onClick={(e) => {
                                GAEvent(
                                  " 2019 Q1",
                                  "  https://drive.google.com/open?id=1xeRbCama7kWwVZ_R8dTeOlIyRlS1X3jL"
                                );
                              }}
                              target="_blank"
                              style={{ color: "#fff", textDecoration: "none" }}
                            >
                              Open
                            </a>
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          2019 Q1 - IA Sensors
                        </TableCell>
                        <TableCell align="center">
                          {" "}
                          https://drive.google.com/open?id=13BZmroQCXye5_P1GDdTJ5yMPQLiSEta_
                        </TableCell>
                        <TableCell align="center">
                          <Button variant="contained" color="primary">
                            <a
                              href={
                                " https://drive.google.com/open?id=13BZmroQCXye5_P1GDdTJ5yMPQLiSEta_"
                              }
                              target="_blank"
                              onClick={(e) => {
                                GAEvent(
                                  "  2019 Q1 - IA Sensors",
                                  "   https://drive.google.com/open?id=13BZmroQCXye5_P1GDdTJ5yMPQLiSEta_"
                                );
                              }}
                              style={{ color: "#fff", textDecoration: "none" }}
                            >
                              Open
                            </a>
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          2019 Q1 - KS Sensors
                        </TableCell>
                        <TableCell align="center">
                          {" "}
                          https://drive.google.com/open?id=1iM_qIpytkMiI8RAEJwWmb3VlqnKQhdtL
                        </TableCell>
                        <TableCell align="center">
                          <Button variant="contained" color="primary">
                            <a
                              href={
                                "https://drive.google.com/open?id=1iM_qIpytkMiI8RAEJwWmb3VlqnKQhdtL"
                              }
                              target="_blank"
                              onClick={(e) => {
                                GAEvent(
                                  "  2019 Q1 - KS Sensors",
                                  "  https://drive.google.com/open?id=1iM_qIpytkMiI8RAEJwWmb3VlqnKQhdtL"
                                );
                              }}
                              style={{ color: "#fff", textDecoration: "none" }}
                            >
                              Open
                            </a>
                          </Button>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell component="th" scope="row">
                          2019 Q2
                        </TableCell>
                        <TableCell align="center">
                          {" "}
                          https://drive.google.com/open?id=1nu9_zrAiye_RBn5QXJvoB2UMrIxZkpNd
                        </TableCell>
                        <TableCell align="center">
                          <Button variant="contained" color="primary">
                            <a
                              href={
                                "  https://drive.google.com/open?id=1nu9_zrAiye_RBn5QXJvoB2UMrIxZkpNd"
                              }
                              target="_blank"
                              onClick={(e) => {
                                GAEvent(
                                  " 2019 Q2",
                                  " https://drive.google.com/open?id=1nu9_zrAiye_RBn5QXJvoB2UMrIxZkpNd"
                                );
                              }}
                              style={{ color: "#fff", textDecoration: "none" }}
                            >
                              Open
                            </a>
                          </Button>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell component="th" scope="row">
                          2019 Q2 - IA Sensors
                        </TableCell>
                        <TableCell align="center">
                          {" "}
                          https://drive.google.com/open?id=1ZtyOtNtp1w5qF0hWdl3he5orW1b3GKv5
                        </TableCell>
                        <TableCell align="center">
                          <Button variant="contained" color="primary">
                            <a
                              href={
                                " https://drive.google.com/open?id=1ZtyOtNtp1w5qF0hWdl3he5orW1b3GKv5"
                              }
                              target="_blank"
                              onClick={(e) => {
                                GAEvent(
                                  " 2019 Q2 - IA Sensors",
                                  "  https://drive.google.com/open?id=1ZtyOtNtp1w5qF0hWdl3he5orW1b3GKv5"
                                );
                              }}
                              style={{ color: "#fff", textDecoration: "none" }}
                            >
                              Open
                            </a>
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          2019 Q2 - KS Sensors
                        </TableCell>
                        <TableCell align="center">
                          {" "}
                          https://drive.google.com/open?id=1mwyx3HV0MrrScn_YnoH2dJOnYU625BJq
                        </TableCell>
                        <TableCell align="center">
                          <Button variant="contained" color="primary">
                            <a
                              href={
                                "  https://drive.google.com/open?id=1mwyx3HV0MrrScn_YnoH2dJOnYU625BJq"
                              }
                              target="_blank"
                              onClick={(e) => {
                                GAEvent(
                                  "2019 Q2 - KS Sensors",
                                  "  https://drive.google.com/open?id=1mwyx3HV0MrrScn_YnoH2dJOnYU625BJq"
                                );
                              }}
                              style={{ color: "#fff", textDecoration: "none" }}
                            >
                              Open
                            </a>
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          2019 Q3
                        </TableCell>
                        <TableCell align="center">
                          {" "}
                          https://drive.google.com/open?id=1RnoPal-ezReqyixmHFd0CQRbc9UZZY-9
                        </TableCell>
                        <TableCell align="center">
                          <Button variant="contained" color="primary">
                            <a
                              href={
                                "  https://drive.google.com/open?id=1RnoPal-ezReqyixmHFd0CQRbc9UZZY-9"
                              }
                              target="_blank"
                              onClick={(e) => {
                                GAEvent(
                                  "  2019 Q3",
                                  "  https://drive.google.com/open?id=1RnoPal-ezReqyixmHFd0CQRbc9UZZY-9"
                                );
                              }}
                              style={{ color: "#fff", textDecoration: "none" }}
                            >
                              Open
                            </a>
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          2019 Q3 - IA Sensors
                        </TableCell>
                        <TableCell align="center">
                          {" "}
                          https://drive.google.com/open?id=1SZhieF2htbcHZUVbf3g5A82Qcmlm0hvh
                        </TableCell>
                        <TableCell align="center">
                          <Button variant="contained" color="primary">
                            <a
                              href={
                                "  https://drive.google.com/open?id=1SZhieF2htbcHZUVbf3g5A82Qcmlm0hvh"
                              }
                              target="_blank"
                              onClick={(e) => {
                                GAEvent(
                                  " 2019 Q3 - IA Sensors",
                                  "  https://drive.google.com/open?id=1SZhieF2htbcHZUVbf3g5A82Qcmlm0hvh"
                                );
                              }}
                              style={{ color: "#fff", textDecoration: "none" }}
                            >
                              Open
                            </a>
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          2019 Q3 - KS Sensors
                        </TableCell>
                        <TableCell align="center">
                          {" "}
                          https://drive.google.com/open?id=1mbMtHB1Ru223zUm2HbOVyykZ0MoS7Y07
                        </TableCell>
                        <TableCell align="center">
                          <Button variant="contained" color="primary">
                            <a
                              href={
                                "  https://drive.google.com/open?id=1mbMtHB1Ru223zUm2HbOVyykZ0MoS7Y07"
                              }
                              target="_blank"
                              onClick={(e) => {
                                GAEvent(
                                  "2019 Q3 - KS Sensors",
                                  "   https://drive.google.com/open?id=1mbMtHB1Ru223zUm2HbOVyykZ0MoS7Y07"
                                );
                              }}
                              style={{ color: "#fff", textDecoration: "none" }}
                            >
                              Open
                            </a>
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          2019 Annual Report
                        </TableCell>
                        <TableCell align="center">
                          {" "}
                          https://drive.google.com/open?id=15nyo_BzVPQuDeNGOaasEcK8jMAVE-Pam
                        </TableCell>
                        <TableCell align="center">
                          <Button variant="contained" color="primary">
                            <a
                              href={
                                "  https://drive.google.com/open?id=15nyo_BzVPQuDeNGOaasEcK8jMAVE-Pam"
                              }
                              target="_blank"
                              onClick={(e) => {
                                GAEvent(
                                  " 2019 Annual Report",
                                  "  https://drive.google.com/open?id=15nyo_BzVPQuDeNGOaasEcK8jMAVE-Pam"
                                );
                              }}
                              style={{ color: "#fff", textDecoration: "none" }}
                            >
                              Open
                            </a>
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          2019 Annual - IA Sensors
                        </TableCell>
                        <TableCell align="center">
                          {" "}
                          https://drive.google.com/open?id=1xJCJ181hMvbqn4Tw2ty2DuMaFIKksTcl
                        </TableCell>
                        <TableCell align="center">
                          <Button variant="contained" color="primary">
                            <a
                              href={
                                "  https://drive.google.com/open?id=1xJCJ181hMvbqn4Tw2ty2DuMaFIKksTcl"
                              }
                              target="_blank"
                              onClick={(e) => {
                                GAEvent(
                                  " 2019 Annual - IA Sensors",
                                  "   https://drive.google.com/open?id=1xJCJ181hMvbqn4Tw2ty2DuMaFIKksTcl"
                                );
                              }}
                              style={{ color: "#fff", textDecoration: "none" }}
                            >
                              Open
                            </a>
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          2019 Annual - KS Sensors
                        </TableCell>
                        <TableCell align="center">
                          {" "}
                          https://drive.google.com/open?id=159-KQ5j38dIo7-zHWNXnf0clY_6SZHyC
                        </TableCell>
                        <TableCell align="center">
                          <Button variant="contained" color="primary">
                            <a
                              href={
                                " https://drive.google.com/open?id=159-KQ5j38dIo7-zHWNXnf0clY_6SZHyC"
                              }
                              target="_blank"
                              onClick={(e) => {
                                GAEvent(
                                  "2019 Annual - KS Sensors",
                                  "  https://drive.google.com/open?id=159-KQ5j38dIo7-zHWNXnf0clY_6SZHyC"
                                );
                              }}
                              style={{ color: "#fff", textDecoration: "none" }}
                            >
                              Open
                            </a>
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          2020 Q1
                        </TableCell>
                        <TableCell align="center">
                          {" "}
                          https://drive.google.com/open?id=1oU1wP8zJ0XyhrvNSiWWRVGnU77lxAb1W
                        </TableCell>
                        <TableCell align="center">
                          <Button variant="contained" color="primary">
                            <a
                              href={
                                "   https://drive.google.com/open?id=1oU1wP8zJ0XyhrvNSiWWRVGnU77lxAb1W"
                              }
                              target="_blank"
                              onClick={(e) => {
                                GAEvent(
                                  "  2020 Q1",
                                  "  https://drive.google.com/open?id=1oU1wP8zJ0XyhrvNSiWWRVGnU77lxAb1W"
                                );
                              }}
                              style={{ color: "#fff", textDecoration: "none" }}
                            >
                              Open
                            </a>
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          2020 Q1 - IA Sensors
                        </TableCell>
                        <TableCell align="center">
                          https://drive.google.com/open?id=1dR6FnTATYD5E-Pd6xl8k1OPgCyTC-jE3
                        </TableCell>
                        <TableCell align="center">
                          <Button variant="contained" color="primary">
                            <a
                              href={
                                "https://drive.google.com/open?id=1dR6FnTATYD5E-Pd6xl8k1OPgCyTC-jE3"
                              }
                              target="_blank"
                              onClick={(e) => {
                                GAEvent(
                                  " 2020 Q1 - IA Sensors",
                                  "  https://drive.google.com/open?id=1dR6FnTATYD5E-Pd6xl8k1OPgCyTC-jE3"
                                );
                              }}
                              style={{ color: "#fff", textDecoration: "none" }}
                            >
                              Open
                            </a>
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          2020 Q1 - KS Sensors
                        </TableCell>
                        <TableCell align="center">
                          https://drive.google.com/open?id=1kpKpn6pQdtNqr3ufKNP4hvaPKfNhRsh3
                        </TableCell>
                        <TableCell align="center">
                          <Button variant="contained" color="primary">
                            <a
                              href={
                                "https://drive.google.com/open?id=1kpKpn6pQdtNqr3ufKNP4hvaPKfNhRsh3"
                              }
                              target="_blank"
                              onClick={(e) => {
                                GAEvent(
                                  " 2020 Q1 - KS Sensors",
                                  "  https://drive.google.com/open?id=1kpKpn6pQdtNqr3ufKNP4hvaPKfNhRsh3"
                                );
                              }}
                              style={{ color: "#fff", textDecoration: "none" }}
                            >
                              Open
                            </a>
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          2020 Q1 - MO Sensors
                        </TableCell>
                        <TableCell align="center">
                          https://drive.google.com/open?id=1vJ3OfKkcxt0Ly3ihtrDDLmdt-9EgDg3n
                        </TableCell>
                        <TableCell align="center">
                          <Button variant="contained" color="primary">
                            <a
                              href={
                                "https://drive.google.com/open?id=1vJ3OfKkcxt0Ly3ihtrDDLmdt-9EgDg3n"
                              }
                              target="_blank"
                              onClick={(e) => {
                                GAEvent(
                                  " 2020 Q1 - MO Sensors",
                                  "  https://drive.google.com/open?id=1vJ3OfKkcxt0Ly3ihtrDDLmdt-9EgDg3n"
                                );
                              }}
                              style={{ color: "#fff", textDecoration: "none" }}
                            >
                              Open
                            </a>
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          2020 Q2 - IA Sensors
                        </TableCell>
                        <TableCell align="center">
                          https://drive.google.com/file/d/1z3EqeP9SaH67BgyvjidJvkTpRi7308l2/view?usp=sharing
                        </TableCell>
                        <TableCell align="center">
                          <Button variant="contained" color="primary">
                            <a
                              href={
                                "https://drive.google.com/file/d/1z3EqeP9SaH67BgyvjidJvkTpRi7308l2/view?usp=sharing"
                              }
                              onClick={(e) => {
                                GAEvent(
                                  " 2020 Q1 - IA Sensors",
                                  "  https://drive.google.com/file/d/1z3EqeP9SaH67BgyvjidJvkTpRi7308l2/view?usp=sharing"
                                );
                              }}
                              target="_blank"
                              style={{ color: "#fff", textDecoration: "none" }}
                            >
                              Open
                            </a>
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          2020 Q2 - KS Sensors
                        </TableCell>
                        <TableCell align="center">
                          https://drive.google.com/file/d/1Erk3VL9eBAqj9HBQ767zaHbihYEW41KM/view?usp=sharing
                        </TableCell>
                        <TableCell align="center">
                          <Button variant="contained" color="primary">
                            <a
                              href={
                                "https://drive.google.com/file/d/1Erk3VL9eBAqj9HBQ767zaHbihYEW41KM/view?usp=sharing"
                              }
                              onClick={(e) => {
                                GAEvent(
                                  " 2020 Q1 - KS Sensors",
                                  "  https://drive.google.com/file/d/1Erk3VL9eBAqj9HBQ767zaHbihYEW41KM/view?usp=sharing"
                                );
                              }}
                              target="_blank"
                              style={{ color: "#fff", textDecoration: "none" }}
                            >
                              Open
                            </a>
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          2020 Q2 - MO Sensors
                        </TableCell>
                        <TableCell align="center">
                          https://drive.google.com/file/d/1F_RvuAeusFNjO3kK1jR0SnzdHMW2cxR9/view?usp=sharing
                        </TableCell>
                        <TableCell align="center">
                          <Button variant="contained" color="primary">
                            <a
                              href={
                                "https://drive.google.com/file/d/1F_RvuAeusFNjO3kK1jR0SnzdHMW2cxR9/view?usp=sharing"
                              }
                              onClick={(e) => {
                                GAEvent(
                                  "2020 Q1 - MO Sensors",
                                  "  https://drive.google.com/file/d/1F_RvuAeusFNjO3kK1jR0SnzdHMW2cxR9/view?usp=sharing"
                                );
                              }}
                              target="_blank"
                              style={{ color: "#fff", textDecoration: "none" }}
                            >
                              Open
                            </a>
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          2020 Q2
                        </TableCell>
                        <TableCell align="center">
                          {" "}
                          https://drive.google.com/file/d/1bMODfmIPZ-GF5fTMGvT8AYPoEZYDE7ku/view
                        </TableCell>
                        <TableCell align="center">
                          <Button variant="contained" color="primary">
                            <a
                              href={
                                "https://drive.google.com/file/d/1bMODfmIPZ-GF5fTMGvT8AYPoEZYDE7ku/view"
                              }
                              target="_blank"
                              style={{ color: "#fff", textDecoration: "none" }}
                            >
                              Open
                            </a>
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          2020 Q3 - IA Sensors
                        </TableCell>
                        <TableCell align="center">
                          https://drive.google.com/file/d/1sqzX8eed2vgUQoXF7pIe8EMBk3or0bYU/view?usp=sharing
                        </TableCell>
                        <TableCell align="center">
                          <Button variant="contained" color="primary">
                            <a
                              href={
                                "https://drive.google.com/file/d/1sqzX8eed2vgUQoXF7pIe8EMBk3or0bYU/view?usp=sharing"
                              }
                              onClick={(e) => {
                                GAEvent(
                                  " 2020 Q1 - IA Sensors",
                                  "  https://drive.google.com/file/d/1sqzX8eed2vgUQoXF7pIe8EMBk3or0bYU/view?usp=sharing"
                                );
                              }}
                              target="_blank"
                              style={{ color: "#fff", textDecoration: "none" }}
                            >
                              Open
                            </a>
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          2020 Q3 - KS Sensors
                        </TableCell>
                        <TableCell align="center">
                          https://drive.google.com/file/d/1Pho56RBRBQ8CRJGxWNehg78TlorxjidK/view?usp=sharing
                        </TableCell>
                        <TableCell align="center">
                          <Button variant="contained" color="primary">
                            <a
                              href={
                                "https://drive.google.com/file/d/1Pho56RBRBQ8CRJGxWNehg78TlorxjidK/view?usp=sharing"
                              }
                              onClick={(e) => {
                                GAEvent(
                                  " 2020 Q1 - KS Sensors",
                                  "  https://drive.google.com/file/d/1Pho56RBRBQ8CRJGxWNehg78TlorxjidK/view?usp=sharing"
                                );
                              }}
                              target="_blank"
                              style={{ color: "#fff", textDecoration: "none" }}
                            >
                              Open
                            </a>
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          2020 Q3 - MO Sensors
                        </TableCell>
                        <TableCell align="center">
                          https://drive.google.com/file/d/10QA1AzXmLffs3Vh3MwA9zYWm6613DLHR/view?usp=sharing
                        </TableCell>
                        <TableCell align="center">
                          <Button variant="contained" color="primary">
                            <a
                              href={
                                "https://drive.google.com/file/d/10QA1AzXmLffs3Vh3MwA9zYWm6613DLHR/view?usp=sharing"
                              }
                              onClick={(e) => {
                                GAEvent(
                                  "2020 Q1 - MO Sensors",
                                  "  https://drive.google.com/file/d/10QA1AzXmLffs3Vh3MwA9zYWm6613DLHR/view?usp=sharing"
                                );
                              }}
                              target="_blank"
                              style={{ color: "#fff", textDecoration: "none" }}
                            >
                              Open
                            </a>
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          2020 Q3
                        </TableCell>
                        <TableCell align="center">
                          {" "}
                          https://drive.google.com/file/d/1_EWyrijk-lQK6ZkCrGefryh4CEABpBuN/view?usp=sharing
                        </TableCell>
                        <TableCell align="center">
                          <Button variant="contained" color="primary">
                            <a
                              href={
                                "https://drive.google.com/file/d/1_EWyrijk-lQK6ZkCrGefryh4CEABpBuN/view?usp=sharing"
                              }
                              target="_blank"
                              style={{ color: "#fff", textDecoration: "none" }}
                            >
                              Open
                            </a>
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          2020 Annual Report
                        </TableCell>
                        <TableCell align="center">
                          {" "}
                          https://drive.google.com/file/d/1fPt7wRQB7mgbRMYzQTaLLkobX5XBN_kN/view?usp=sharing
                        </TableCell>
                        <TableCell align="center">
                          <Button variant="contained" color="primary">
                            <a
                              href={
                                "  https://drive.google.com/file/d/1fPt7wRQB7mgbRMYzQTaLLkobX5XBN_kN/view?usp=sharing"
                              }
                              onClick={(e) => {
                                GAEvent(
                                  "2020 Annual Report",
                                  "https://drive.google.com/file/d/1fPt7wRQB7mgbRMYzQTaLLkobX5XBN_kN/view?usp=sharing"
                                );
                              }}
                              target="_blank"
                              style={{ color: "#fff", textDecoration: "none" }}
                            >
                              Open
                            </a>
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          2020 Annual Report - IA Sensors
                        </TableCell>
                        <TableCell align="center">
                          https://drive.google.com/file/d/1UoCLrnCUP3j8GgZXLvcKCAYm5xqtgKf1/view?usp=sharing
                        </TableCell>
                        <TableCell align="center">
                          <Button variant="contained" color="primary">
                            <a
                              href={
                                "https://drive.google.com/file/d/1UoCLrnCUP3j8GgZXLvcKCAYm5xqtgKf1/view?usp=sharing"
                              }
                              onClick={(e) => {
                                GAEvent(
                                  "  2020 Annual Report - IA Sensors",
                                  "  https://drive.google.com/file/d/1UoCLrnCUP3j8GgZXLvcKCAYm5xqtgKf1/view?usp=sharing"
                                );
                              }}
                              target="_blank"
                              style={{ color: "#fff", textDecoration: "none" }}
                            >
                              Open
                            </a>
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          2020 Annual Report - KS Sensors
                        </TableCell>
                        <TableCell align="center">
                          https://drive.google.com/file/d/1GcPkYifeQzSNmy13t72Ftd2HEppNnu-5/view?usp=sharing
                        </TableCell>
                        <TableCell align="center">
                          <Button variant="contained" color="primary">
                            <a
                              href={
                                "https://drive.google.com/file/d/1GcPkYifeQzSNmy13t72Ftd2HEppNnu-5/view?usp=sharing"
                              }
                              onClick={(e) => {
                                GAEvent(
                                  "  2020 Annual Report - KS Sensors",
                                  "  https://drive.google.com/file/d/1GcPkYifeQzSNmy13t72Ftd2HEppNnu-5/view?usp=sharing"
                                );
                              }}
                              target="_blank"
                              style={{ color: "#fff", textDecoration: "none" }}
                            >
                              Open
                            </a>
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          2020 Annual Report - MO Sensors
                        </TableCell>
                        <TableCell align="center">
                          https://drive.google.com/file/d/1piouIzopkV8NgO04EJBwTpLNmgkX2KVg/view?usp=sharing
                        </TableCell>
                        <TableCell align="center">
                          <Button variant="contained" color="primary">
                            <a
                              href={
                                "https://drive.google.com/file/d/1piouIzopkV8NgO04EJBwTpLNmgkX2KVg/view?usp=sharing"
                              }
                              onClick={(e) => {
                                GAEvent(
                                  " 2020 Annual Report - MO Sensors",
                                  "  https://drive.google.com/file/d/1piouIzopkV8NgO04EJBwTpLNmgkX2KVg/view?usp=sharing"
                                );
                              }}
                              target="_blank"
                              style={{ color: "#fff", textDecoration: "none" }}
                            >
                              Open
                            </a>
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          2021 Q1
                        </TableCell>
                        <TableCell align="center">
                          {" "}
                          https://drive.google.com/file/d/1voiY-pitucmYtnyjawRNaMGAxh7--4dY/view
                        </TableCell>
                        <TableCell align="center">
                          <Button variant="contained" color="primary">
                            <a
                              href={
                                "   https://drive.google.com/file/d/1voiY-pitucmYtnyjawRNaMGAxh7--4dY/view"
                              }
                              onClick={(e) => {
                                GAEvent(
                                  "Report 2021 Q1",
                                  "https://drive.google.com/file/d/1voiY-pitucmYtnyjawRNaMGAxh7--4dY/view"
                                );
                              }}
                              target="_blank"
                              style={{ color: "#fff", textDecoration: "none" }}
                            >
                              Open
                            </a>
                          </Button>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell component="th" scope="row">
                          2021 Q1 - KS Sensors
                        </TableCell>
                        <TableCell align="center">
                          https://drive.google.com/file/d/1L1s1JjQGEM0HfB6TWwan2AwcKC51kAVE/view?usp=sharing
                        </TableCell>
                        <TableCell align="center">
                          <Button variant="contained" color="primary">
                            <a
                              href={
                                "https://drive.google.com/file/d/1L1s1JjQGEM0HfB6TWwan2AwcKC51kAVE/view?usp=sharing"
                              }
                              onClick={(e) => {
                                GAEvent(
                                  " 2020 Q1 - IA Sensors",
                                  "  https://drive.google.com/file/d/1L1s1JjQGEM0HfB6TWwan2AwcKC51kAVE/view?usp=sharing"
                                );
                              }}
                              target="_blank"
                              style={{ color: "#fff", textDecoration: "none" }}
                            >
                              Open
                            </a>
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          2021 Q1 -MO Sensors
                        </TableCell>
                        <TableCell align="center">
                          https://drive.google.com/file/d/11DrMiWSFQUQCY4oi5zofDKaDI7WqgUAi/view?usp=sharing
                        </TableCell>
                        <TableCell align="center">
                          <Button variant="contained" color="primary">
                            <a
                              href={
                                "https://drive.google.com/file/d/11DrMiWSFQUQCY4oi5zofDKaDI7WqgUAi/view?usp=sharing"
                              }
                              onClick={(e) => {
                                GAEvent(
                                  " 2020 Q1 - KS Sensors",
                                  "  https://drive.google.com/file/d/11DrMiWSFQUQCY4oi5zofDKaDI7WqgUAi/view?usp=sharing"
                                );
                              }}
                              target="_blank"
                              style={{ color: "#fff", textDecoration: "none" }}
                            >
                              Open
                            </a>
                          </Button>
                        </TableCell>
                      </TableRow>
                      {/* All Q2 */}
                      <TableRow>
                        <TableCell component="th" scope="row">
                          2021 Q2
                        </TableCell>
                        <TableCell align="center">
                          https://drive.google.com/file/d/1O5m9lBXxCkZHB1AZ4Yxk2XpNL6-1liib/view?usp=sharing
                        </TableCell>
                        <TableCell align="center">
                          <Button variant="contained" color="primary">
                            <a
                              href={
                                "https://drive.google.com/file/d/1O5m9lBXxCkZHB1AZ4Yxk2XpNL6-1liib/view?usp=sharing"
                              }
                              onClick={(e) => {
                                GAEvent(
                                  " 2020 Q2",
                                  "  https://drive.google.com/file/d/1O5m9lBXxCkZHB1AZ4Yxk2XpNL6-1liib/view?usp=sharing"
                                );
                              }}
                              target="_blank"
                              style={{ color: "#fff", textDecoration: "none" }}
                            >
                              Open
                            </a>
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          2021 Q2 - IA Sensors
                        </TableCell>
                        <TableCell align="center">
                          https://docs.google.com/spreadsheets/d/15yV5KULkcqSpSHtEWOfqBl4IhDuIe0x6/edit?usp=sharing&ouid=114128508673891658631&rtpof=true&sd=true
                        </TableCell>
                        <TableCell align="center">
                          <Button variant="contained" color="primary">
                            <a
                              href={
                                "https://docs.google.com/spreadsheets/d/15yV5KULkcqSpSHtEWOfqBl4IhDuIe0x6/edit?usp=sharing&ouid=114128508673891658631&rtpof=true&sd=true"
                              }
                              onClick={(e) => {
                                GAEvent(
                                  " 2020 Q2 - IA Sensors",
                                  " https://docs.google.com/spreadsheets/d/15yV5KULkcqSpSHtEWOfqBl4IhDuIe0x6/edit?usp=sharing&ouid=114128508673891658631&rtpof=true&sd=true"
                                );
                              }}
                              target="_blank"
                              style={{ color: "#fff", textDecoration: "none" }}
                            >
                              Open
                            </a>
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          2021 Q2 - KS Sensors
                        </TableCell>
                        <TableCell align="center">
                          https://docs.google.com/spreadsheets/d/1k8Cnkhmn33Ye1a8IIiR2CCiRzbwRb2vP/edit?usp=sharing&ouid=114128508673891658631&rtpof=true&sd=true
                        </TableCell>
                        <TableCell align="center">
                          <Button variant="contained" color="primary">
                            <a
                              href={
                                "https://docs.google.com/spreadsheets/d/1k8Cnkhmn33Ye1a8IIiR2CCiRzbwRb2vP/edit?usp=sharing&ouid=114128508673891658631&rtpof=true&sd=true"
                              }
                              onClick={(e) => {
                                GAEvent(
                                  " 2020 Q2 - KS Sensors",
                                  "https://docs.google.com/spreadsheets/d/1k8Cnkhmn33Ye1a8IIiR2CCiRzbwRb2vP/edit?usp=sharing&ouid=114128508673891658631&rtpof=true&sd=true"
                                );
                              }}
                              target="_blank"
                              style={{ color: "#fff", textDecoration: "none" }}
                            >
                              Open
                            </a>
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          2021 Q2 - MO Sensors
                        </TableCell>
                        <TableCell align="center">
                          https://docs.google.com/spreadsheets/d/1dvUnfuP2WyfvgYuxMsZ7s3BGgYAolon4/edit?usp=sharing&ouid=114128508673891658631&rtpof=true&sd=true
                        </TableCell>
                        <TableCell align="center">
                          <Button variant="contained" color="primary">
                            <a
                              href={
                                "https://docs.google.com/spreadsheets/d/1dvUnfuP2WyfvgYuxMsZ7s3BGgYAolon4/edit?usp=sharing&ouid=114128508673891658631&rtpof=true&sd=true"
                              }
                              onClick={(e) => {
                                GAEvent(
                                  " 2020 Q2 - MO Sensors",
                                  "https://docs.google.com/spreadsheets/d/1dvUnfuP2WyfvgYuxMsZ7s3BGgYAolon4/edit?usp=sharing&ouid=114128508673891658631&rtpof=true&sd=true"
                                );
                              }}
                              target="_blank"
                              style={{ color: "#fff", textDecoration: "none" }}
                            >
                              Open
                            </a>
                          </Button>
                        </TableCell>
                      </TableRow>
                      {/* 2020 Q2 - KS Sensors */}

                      {/* 2021 Q3 Report */}
                      <TableRow>
                        <TableCell component="th" scope="row">
                          2021 Q3
                        </TableCell>
                        <TableCell align="center">
                          https://drive.google.com/file/d/1ZOORdpaXzeTZ_wAddTJE8_w55lnlhBEG/view?usp=sharing
                        </TableCell>
                        <TableCell align="center">
                          <Button variant="contained" color="primary">
                            <a
                              href={
                                "https://drive.google.com/file/d/1ZOORdpaXzeTZ_wAddTJE8_w55lnlhBEG/view?usp=sharing"
                              }
                              target="_blank"
                              onClick={(e) => {
                                GAEvent(
                                  " 2021 Q3",
                                  "  https://drive.google.com/file/d/1ZOORdpaXzeTZ_wAddTJE8_w55lnlhBEG/view?usp=sharing"
                                );
                              }}
                              style={{ color: "#fff", textDecoration: "none" }}
                            >
                              Open
                            </a>
                          </Button>
                        </TableCell>
                      </TableRow>

                      {/* 2021 Annual Report */}
                      <TableRow>
                        <TableCell component="th" scope="row">
                          2021 Annual Report
                        </TableCell>
                        <TableCell align="center">
                          https://drive.google.com/file/d/1InQ9dfNH4gihQiHo2dbwCkOW5KjLwiBJ/view?usp=sharing
                        </TableCell>
                        <TableCell align="center">
                          <Button variant="contained" color="primary">
                            <a
                              href={
                                "https://drive.google.com/file/d/1InQ9dfNH4gihQiHo2dbwCkOW5KjLwiBJ/view?usp=sharing"
                              }
                              target="_blank"
                              onClick={(e) => {
                                GAEvent(
                                  " 2021 Annual Report ",
                                  " https://drive.google.com/file/d/1InQ9dfNH4gihQiHo2dbwCkOW5KjLwiBJ/view?usp=sharing"
                                );
                              }}
                              style={{ color: "#fff", textDecoration: "none" }}
                            >
                              Open
                            </a>
                          </Button>
                        </TableCell>
                      </TableRow>
                      {/* Delay Definition */}
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Delay Definition
                        </TableCell>
                        <TableCell align="center">
                          https://drive.google.com/file/d/156CnYVkFMMZW6cgopQKO-NYM09urdMAs/view?usp=sharing
                        </TableCell>
                        <TableCell align="center">
                          <Button variant="contained" color="primary">
                            <a
                              href={
                                "https://drive.google.com/file/d/156CnYVkFMMZW6cgopQKO-NYM09urdMAs/view?usp=sharing"
                              }
                              target="_blank"
                              onClick={(e) => {
                                GAEvent(
                                  " Delay Definition",
                                  "  https://drive.google.com/file/d/156CnYVkFMMZW6cgopQKO-NYM09urdMAs/view?usp=sharing"
                                );
                              }}
                              style={{ color: "#fff", textDecoration: "none" }}
                            >
                              Open
                            </a>
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Grid>
            {/* <QuaterlyReport /> */}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper className={`${classes.paper} bgFooter`} style={{ top: 0 }}>
          <hr />
          <p className="footerNew">Copyright © Etalyc 2020.</p>
        </Paper>
      </Grid>
    </div>
  );
}

export default NewReport;
