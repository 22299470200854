/*
|**************************************************************************
| C2C Component
|**************************************************************************
|  
*/

import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import IconButton from '@material-ui/core/IconButton';
import LastPageIcon from '@material-ui/icons/LastPage';
import TableFooter from '@material-ui/core/TableFooter';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import Axios from 'axios';
import * as Constants from './../utilities/Constants';

/***
 * Module Name : C2C
 * Description : 
 * Auther :
 * Date : 
 */

const useStyles1 = makeStyles(theme => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = event => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = event => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = event => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};


function C2C() {
    const [lists, setList] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, lists.length - page * rowsPerPage);
    let token = localStorage.getItem('mcomp_token');

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    // All User List API Call

    useEffect(() => {
        // Get C2C Data
        Axios
            .get(Constants.NODE_API_BASE_URL + `/api/getC2CData`, {
                headers: { 'authorization': "Token " + token },
            })
            .then(res => {
               let  data = []
                res.data.map(result=>{
                if(result.kpi==="Speed"){
                    data.push(result)
                }
                if(result.state==="Kansas"){
                    if(result.kpi==="ATSM Detail"){
                        data.push(result);
                      }
                  }  
                  if(result.state ==="Missouri"){
                    data.push(result)
                  }
                  if(result.state==="Oklahoma"){
                    data.push(result)
                  }
                });
                setList(data);
            })
            .catch(err => {
                console.log("Error", err)
            })
    }, []);


    return (
        <div>
            <TableContainer component={Paper} className="width-80">
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>State</TableCell>
                            <TableCell align="center">KPI</TableCell>
                            <TableCell align="center">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {(rowsPerPage > 0
                            ? lists.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : lists
                        ).map((list, index) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    {list.state}
                                </TableCell>
                                <TableCell align="center">{list.kpi}</TableCell>
                                <TableCell align="center">
                                    {/* <Button onClick={() => downloadFile(list.link)} > Download</Button> */}
                                    <Button variant="contained" color="primary">
                                        <a onClick={() => { window.location.href = list.s3protectedUrl; }} href={list.s3protectedUrl} style={{ 'color': '#fff', 'textDecoration': 'none' }}>Download</a>
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>

                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                // colSpan={3}
                                count={lists.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>

                </Table>
            </TableContainer>


        </div>
    )
}

export default C2C;