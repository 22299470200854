import React,{useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ReactGA from "react-ga";
import * as Constants from './../../utilities/Constants';

import NewHeader from './NewHeader';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
}));

function NewAbout() {
    const classes = useStyles();
    useEffect(() => {
        ReactGA.initialize(Constants.GOOGLE_ANALYTICS_KEY);
        ReactGA.pageview(window.location.pathname + window.location.search); 
      }, []);
    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={12}>
                    <NewHeader />
                </Grid>
                <Grid item xs={12}>
                    <img src="/map.png" className="img-fluid mb-100" 
                    style={{ width: '70%',margin: 'auto',marginTop: '80px',display: 'block', }} alt="img" />
                    {/* <img src="etalyc_logo_white.png" style={{ width: '100px',position:'absolute',top:'49%',right:'46%'}} alt="Etalyc"/> */}
                </Grid>
                <Grid item xs={12}>
                    <div className="container">
                        <Paper className={`${classes.paper}`}>
                            <div className="padding12 ">
                                <p className="justify">
                                    The Multistate Corridor Operations and Management Program (MCOMP) aims to provide greater information sharing between the five ITS Heartland (ITSH) state
                                    transportation agencies (Missouri, Iowa, Nebraska, Kansas, and Oklahoma) and their respective customers,
                                    in order to improve the movement of commercial vehicles, provide better traveler information systems,
                                    and cooperatively plan operations throughout the region.
                                 </p>
                                <p className="justify">
                                    Transportation data is available from many public and private sources. However, the data may vary significantly from state to state in terms of collection method, data quality,
                                    existence of gaps, availability or timeframe (daily, monthly, and quarterly), format (shape files, documents, tables,
                                    videos etc.) and suitability.
                                 </p>
                                <p className="justify">
                                    This Integrated Data Warehouse (IDW) is a cloud-based, scalable, and distributed data warehouse system.
                                    The system takes advantage of recent advances in big data analytics and user-centric visualization to
                                    facilitate the storage and integration of very large,
                                    structured and unstructured data across different private and public databases.
                                   </p>
                                <p className="justify">
                                    The system handles fast, disparate, noisy data streams and provide users a high throughput access to the integrated
                                    datasets. The system also transforms heterogeneous streams of data to usable knowledge, provides data driven insights
                                    and decision assistance.
                                </p>
                            </div>
                        </Paper>
                    </div>
                </Grid>

            </Grid>

            <Grid item xs={12}>
                <Paper className={`${classes.paper} bgFooter`}>
                    <hr />
                    <p className="footerNew">Copyright © Etalyc 2020.</p>
                </Paper>
            </Grid>

        </div>
    )
}

export default NewAbout

