/*
|**************************************************************************
| User List Component
|**************************************************************************
|   This file is resposible for showing list of All users which are signed up.
|   It can also be use to activate/deactivate the user.
*/

import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import IconButton from '@material-ui/core/IconButton';
import LastPageIcon from '@material-ui/icons/LastPage';
import Typography from '@material-ui/core/Typography';
import TableFooter from '@material-ui/core/TableFooter';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import DialogContentText from '@material-ui/core/DialogContentText';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import './userList.css';
import Axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import * as Constants from './../utilities/Constants';

/***
 * Module Name : UserListAccess
 * Description : To get list of all users
 * Auther : Pankaj Kumar
 * Date : 20 Feb 2020
 */

const useStyles1 = makeStyles(theme => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = event => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = event => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = event => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};


function UserListAccess() {
    const [lists, setList] = useState([]);
    const [selectedUser, setSelectedUser] = useState();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, lists.length - page * rowsPerPage);
    const [open, setOpen] = React.useState(false);
    //Active and Deactive user 
    const [loggedInUser, setLoggedInUser] = React.useState();
    let token = localStorage.getItem('mcomp_token');

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    // forgot password 
    const forgotLink = (event, list) => {
        let body = {
            "email": list.email
        }
        Axios
            .post(Constants.NODE_API_BASE_URL + `/api/users/reset-password`, body)
            .then(res => {
                toast.success("Forgot password link sent successfully.");
            }).catch(err => {
                toast.error("Something went wrong. Please try again later.");
            })
    }

    // All User List API Call

    useEffect(() => {

        // Get user's profile
        Axios
            .get(Constants.NODE_API_BASE_URL + `/api/users/profile`, {
                headers: { 'authorization': token },
            })
            .then(res => {
                setLoggedInUser(res['data']['users']);
            })
            .catch(err => {
                console.log("errorro", err)
            })

        // Get lists of all users
        Axios
            .get(Constants.NODE_API_BASE_URL + `/api/users/listuser`, {
                headers: { 'authorization': token },
            })
            .then(res => {
                setList(res['data']['users'])

            })
            .catch(err => {
                console.log("error", err);
            })
    }, [open]);

    // Deactive/Activate user list


    const onNoButtonClicked = (e, list) => {
        setOpen(false);
    }
    const onYesButtonClicked = (e, list) => {
        let body = {
            "email": list.email,
            "isActive": !list.isActive
        }
        Axios
            .post(Constants.NODE_API_BASE_URL + `/api/users/allowuser`, body)
            .then(res => {
                setOpen(false);
                if (list.isActive === true) {
                    toast.success("User deactivated succesfully.");
                } if (list.isActive === false) {
                    toast.success("User activated succesfully and activation link has been sent.");
                }
            }).catch(err => {
                toast.error("Something went wrong. Please try again later.");
            })
    }

    const handleChange = (e, list) => {
        setOpen(true);
        setSelectedUser(list);
    }


    return (
        <div>
            <Typography component={'h4'} variant={'h4'}>  Access List</Typography>
            {loggedInUser && loggedInUser.role === 'nonadmin' && <Typography component={'p'} variant={'p'}>  You don't have  access to view this page... </Typography>}
            {loggedInUser && loggedInUser.role === 'admin' && <TableContainer component={Paper} className="width-80">
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="center">Role</TableCell>
                            <TableCell align="center">Organization</TableCell>
                            <TableCell align="center">Email</TableCell>
                            <TableCell align="center">Enable User</TableCell>
                            <TableCell align="center">Send Password Link</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {(rowsPerPage > 0
                            ? lists.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : lists
                        ).map(list => (
                            <TableRow key={list._id}>
                                <TableCell component="th" scope="row">
                                    {list.name}
                                </TableCell>
                                <TableCell align="center">{list.role}</TableCell>
                                <TableCell align="center">{list.organization}</TableCell>
                                <TableCell align="center">{list.email}</TableCell>
                                <TableCell align="center">
                                    <Switch
                                        checked={list.isActive}
                                        onChange={(e) => { handleChange(e, list) }}
                                        value="isActive"
                                        color="primary"
                                    />

                                </TableCell>
                                <TableCell align="center">
                                    <Button variant="contained" color="primary" onClick={(e) => { forgotLink(e, list) }}>
                                        Send
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>

                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                // colSpan={3}
                                count={lists.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>

                </Table>


                <Dialog
                    open={open}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {selectedUser && selectedUser.isActive ? 'Do you really want to enable this user.' : 'Do you really want to activate this user.'}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={(e) => { onNoButtonClicked(e, selectedUser) }} color="secondary"> No </Button>
                        <Button color="primary" onClick={(e) => { onYesButtonClicked(e, selectedUser) }} autoFocus> Yes </Button>
                    </DialogActions>
                </Dialog>
            </TableContainer>
            }
            <ToastContainer />

        </div>
    )
}

export default UserListAccess;
