import React from "react";
import citySchema from '../data/citySchema';
import C2C from '../components/c2c';
import Users from './users';
import UserListAccess from './UserListAccess';
import UnderDeveloment from './UnderDeveloment';
import Search from './Search';
import QuaterlyReport from '../components/QuaterlyReport'
import colors from '../themes/colors';
let themeType = citySchema['city']['heartland'].theme

const comps = {
    c2c: <C2C></C2C>,
    users: <Users></Users>,
    search: <Search></Search>,
    userListAccess: <UserListAccess></UserListAccess>,
    underdevelopment :  <UnderDeveloment></UnderDeveloment>,
    quaterlyReport: <QuaterlyReport></QuaterlyReport>

}
/*Loads Tableau data */
export default function Container(props) {
    const { link } = props
    const comp = link.indexOf("https");
    return (
        <div style={{ background: "#2f3333 important!" }}>
            {comp >= 0 ? (
                <iframe
                    title="w"
                    src={link}
                    width="100%"
                    height="955"
                    frameBorder="0"
                ></iframe>
            ) : (
                    comps[link]
                )}
        </div>
    );
}