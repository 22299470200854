/*
|**************************************************************************
| Request History Component
|**************************************************************************
|   This file is resposible for showing list of request history which user searched previously
*/

import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';

import IconButton from '@material-ui/core/IconButton';
import LastPageIcon from '@material-ui/icons/LastPage';
import Typography from '@material-ui/core/Typography';
import TableFooter from '@material-ui/core/TableFooter';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { CircularProgress } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import CancelIcon from '@material-ui/icons/Cancel';
import ReactGA from "react-ga";

// import './userList.css';
import Axios from 'axios';
import { useHistory } from "react-router-dom";
import * as Constants from './../utilities/Constants';
import { ToastContainer, toast } from 'react-toastify';
import * as moment from 'moment';
import socketIOClient from "socket.io-client";

/***
 * Module Name : UserListAccess
 * Description : To get list of all users
 * Auther : 
 * Date : 20 Feb 2020
 */
    // Initialize socket io
const socket = socketIOClient(Constants.SOCKET_ENDPOINT);
const useStyles1 = makeStyles(theme => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    useEffect(() => {
        ReactGA.initialize(Constants.GOOGLE_ANALYTICS_KEY);
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    const GAEvent = (title, link) => {
        console.log("event", title);
        ReactGA.event({
            category: 'Report: ' + title,
            action: link
        })
    };
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = event => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = event => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = event => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };



    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

function ButtonComponent(props) {
    const { onClick, loading } = props;
    if (props.status === "FAILED") {
        // return "FAILED";
        return (
            <Button color="secondary">
                FAILED
            </Button>
        )
    }
    return (
        <Button color="primary" onClick={onClick} disabled={loading}>
            {loading && <CircularProgress size={14} />}
            {!loading && <GetAppIcon />}
        </Button>
    );
}

function RequestHistory() {


    const [data, setData] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const [errorMsg, setErrorMsg] = useState('Something went wrong. Please try again.');
    const [showMsg, setShowMsg] = useState(false);
    const [checkInProgressQuery, setCheckInProgressQuery] = useState(true);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Socket listner
    socket.on("AthenaQueryUpdate", res => {
        // Below code is giving flickering 
        // data.filter((e) => e._id === res._id).forEach(v => { v.data = res.updatedObj.data; v.status = res.status; });
        // setData([...data]);
        // Reload the data
        console.log(res);
        if(res && res.status === "TAKING_TIME"){
            if(checkInProgressQuery){
                setErrorMsg(res.msg?res.msg:"The request is in progress. Check back in an hour.");
                setShowMsg(true);
            }else{
                setCheckInProgressQuery(false);
                setShowMsg(false);
            }
        }else{
            // Set query time taking to false once we get the proper response
            setCheckInProgressQuery(false);
        Axios
            .get(Constants.NODE_API_BASE_URL + "/api/search/history",
                { headers: { 'Authorization': 'Token ' + localStorage.getItem('mcomp_token') } }
            )
            .then(res => {
                setData(res.data);
                setShowMsg(false);  
            })
            .catch(err => {
                console.log("error", err);
            })
        }
    });

    useEffect(() => {
        // Get request history
        Axios
            .get(Constants.NODE_API_BASE_URL + `/api/search/history`,
                { headers: { 'Authorization': 'Token ' + localStorage.getItem('mcomp_token') } }
            )
            .then(res => {
                setData(res.data);
            })
            .catch(err => {
                console.log("error", err);
            })
    }, []);
    const history = useHistory();
    //Back button Style
    const btnRight = {
        "float": "right", "margin": "10px", "width": "100px"
    }
    const queryStyle = {
        textAlign: 'right', marginTop: ' 20px', fontSize: '12px', color: 'forestgreen'
    }



    return (
        <div>
            <Typography component={'h4'} variant={'h4'} style={{ textAlign: 'center', margin: 20 }}>  Request History</Typography>
            {showMsg && <Typography component={'h6'} variant={'h6'}
                style={{ textAlign: 'center', margin: 20, color: 'red' }}>
                {errorMsg}
                <CancelIcon style={{cursor:'pointer'}} onClick={()=>{setShowMsg(false)}}></CancelIcon>
                </Typography>
            }
            
            <TableContainer component={Paper} className="width-80">
                <Button variant="contained" color="primary" style={btnRight} onClick={() => { history.push("/dashboard") }}>Back</Button>
                <p style={queryStyle}><sup>*</sup> For failed queries please contact : support@etalyc.com</p>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Sr No</TableCell>
                            <TableCell align="center">State</TableCell>
                            <TableCell align="center">Type of Data</TableCell>
                            <TableCell align="center">Date From</TableCell>
                            <TableCell align="center">Date To</TableCell>
                            {/* <TableCell align="center">Status</TableCell> */}
                            <TableCell align="center">Created Date </TableCell>
                            <TableCell align="center">Link </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {(rowsPerPage > 0
                            ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : data
                        ).map((item, index) => (
                            <TableRow key={item._id}>
                                <TableCell align="center">{++index + (page * rowsPerPage)}</TableCell>
                                <TableCell component="th" scope="row" align="center">
                                    {item.state}
                                </TableCell>
                                <TableCell align="center">{item.type_of_data}</TableCell>
                                <TableCell align="center">{moment(item.dateFrom).format('YYYY-MM-DD')}</TableCell>
                                <TableCell align="center">{moment(item.dateTo).format('YYYY-MM-DD')}</TableCell>
                                {/* <TableCell align="center">{item.status}</TableCell> */}
                                <TableCell align="center">{moment(item.createdAt).format('YYYY-MM-DD h:mm:ss a')}</TableCell>
                                <TableCell align="center">
                                    {item.data && Object.entries(item.data).map((datai, key) => (
                                        <div style={{ margin: 10 }}><span style={{ margin: 10, width: 150, textAlign: 'center' }}>{datai[0]}</span>
                                            <ButtonComponent status={(moment(item.createdAt).add(10, 'minutes')
                                                .isBefore(/*now*/) && item.data[datai[0]].status !== "DONE") ?
                                                "FAILED" : item.data[datai[0]].status} loading={item.data[datai[0]]
                                                    && item.data[datai[0]].status === "IN_PROGRESS"} disabled={item.data[datai[0]]
                                                        && item.data[datai[0]].s3ProtectedUrl === ""}
                                                value={item.data[datai[0]].s3ProtectedUrl}
                                                href={item.data[datai[0]].s3ProtectedUrl}
                                                onClick={() => { window.location.href = item.data[datai[0]].s3ProtectedUrl; }}>

                                            </ButtonComponent>
                                        </div>
                                    ))}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>

                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                count={data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <ToastContainer />
        </div>
    )
}

export default RequestHistory;
