/*
|**************************************************************************
| User List Component
|**************************************************************************
|   This file is resposible for showing list of All users which are signed up.
|   It can also be use to activate/deactivate the user.
*/

import React, { useState, useEffect } from 'react';
import './userList.css';

/***
 * Module Name : Page under development
 * Description : To show page under development message
 * Auther : Sumit Patil
 * Date : 30 Apr 2020
 */

function UnderDeveloment() {

    return (
        <div>
            Page is under Development
        </div>
    )
}

export default UnderDeveloment;
