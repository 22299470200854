import React, { useState } from 'react';
// import Link from '@material-ui/core/Link';

import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as Constants from './../utilities/Constants';
import axios from 'axios';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';

function Copyright() {
    const etalicColor = {
        color: '#5d6163'
    }
    return (
        <Typography variant="body2" color="textSecondary" align="center" style={{ paddingBottom: '25px' }}>
            {'Copyright © '}
            <Link color="inherit" to="https://etalyc.com/" style={etalicColor}>
                Etalyc.com
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#ffffff',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    container: {
        backgroundColor: '#ffffff',
        //padding: '5px',
    }
}));

export default function SignUp() {
    const classes = useStyles();
    const [emsg, setEmsg] = useState(false);
    const [errorResponseMessage, setErrorResponseMessage] = useState('');
    const [confirmEmailErrorMessage, setConfirmEmailErrorMessage] = useState(false);
    //Loader
    const [loader, setLoader] = useState(false);
    const divStyle = {
        width: '100%'
    };
    const errorMsg = {
        color: 'red'
    }
    const linkColor = {
        color: '#3f51b5',
    };
    const submitHeader = {
        boxShadow: '0px 0px 9px #978e8e',
        padding: '12px',
        background: '#5fa8d6',
        color: '#fff'
    }


    return (
        <Container component="main" maxWidth="xs" className={classes.container}>
            <CssBaseline />
            <div className={classes.paper}>
                <Grid item xs={12}>
                    <img src={require("../assets/etalyc.jpg")} width="400" alt="img" style={{ marginTop: '25px', marginBottom: '25px' }}></img>
                </Grid>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign up
                </Typography>
                {/* for Loader */}
                {loader === true &&
                    <div className={classes.root}>
                        <CircularProgress />
                    </div>
                }
                {emsg &&
                    <div className={classes.form}>
                        <h4 style={submitHeader}>
                            Thank you for registering. <br></br> The username and password will be sent to your email, after approved by Admin.
                        </h4>
                    </div>
                }
                <h4 style={errorMsg}>{errorResponseMessage}</h4>
                <div className={classes.form} >
                    <Formik
                        initialValues={{ first: '', last: '', organization: '', email: '', cemail: '' }}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            setSubmitting(true);
                            setLoader(true)
                            if (values.email === values.cemail) {
                                let user = {
                                    name: values.first + ' ' + values.last,
                                    email: values.email,
                                    organization: values.organization,
                                }
                                axios.post(Constants.NODE_API_BASE_URL + `/api/users/register`, user).then(res => {
                                    toast.success("Your request has been submitted.")
                                    setEmsg(true);
                                    setLoader(false);
                                    resetForm()
                                }).catch(err => {
                                    setSubmitting(false);
                                    setLoader(false);
                                    if (err.response.data.errors['message']) {
                                        setErrorResponseMessage(err.response.data.errors['message']);
                                        toast.error(err.response.data.errors['message'])
                                    }
                                })
                            }
                            else {
                                setErrorResponseMessage('Email and Confirm Email does not matched!')
                                setLoader(false);
                                setSubmitting(false);
                                toast.error()
                                setConfirmEmailErrorMessage(true)
                            }
                        }
                        }

                        validationSchema={Yup.object().shape({
                            email: Yup.string()
                                .email()
                                .required('Email is Required'),
                            cemail: Yup.string()
                                .email()
                                .required('Confirm Email is Required'),
                            first: Yup.string()
                                .required('First Name is Required'),
                            last: Yup.string()
                                .required('Last Name is Required'),
                            organization: Yup.string()
                                .required('Organization is Required'),
                        })}
                    >
                        {(props) => {
                            const {
                                values,
                                touched,
                                errors,
                                isSubmitting,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                            } = props;
                            return (
                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                // autoFocus
                                                label="First Name"
                                                name="first"
                                                variant="outlined"
                                                className={classes.textField}
                                                value={values.first}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.first && touched.first}
                                                helperText={(errors.first && touched.first) && errors.first}
                                                margin="normal"
                                                onClick={() => { setErrorResponseMessage('') }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label="Last Name"
                                                name="last"
                                                variant="outlined"
                                                value={values.last}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.last && touched.last}
                                                helperText={(errors.last && touched.last) && errors.last}
                                                margin="normal"
                                                onClick={() => { setErrorResponseMessage('') }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField style={divStyle}
                                                label="Email"
                                                name="email"
                                                variant="outlined"
                                                value={values.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.email && touched.email}
                                                helperText={(errors.email && touched.email) && errors.email}
                                                margin="normal"
                                                onClick={() => { setErrorResponseMessage('') }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField style={divStyle}
                                                label="Confirm Email"
                                                name="cemail"
                                                variant="outlined"
                                                value={values.cemail}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={(errors.cemail !== errors.email) && touched.cemail}
                                                helperText={(errors.cemail && touched.cemail) && errors.cemail}
                                                margin="normal"
                                                onClick={() => { setErrorResponseMessage('') }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField style={divStyle}
                                                label="Organization"
                                                name="organization"
                                                variant="outlined"
                                                value={values.organization}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.organization && touched.organization}
                                                helperText={(errors.organization && touched.organization) && errors.organization}
                                                margin="normal"
                                                onClick={() => { setErrorResponseMessage('') }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                        disabled={isSubmitting}
                                    >
                                        Sign Up
                                    </Button>
                                </form>
                            );
                        }}
                    </Formik>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <Link to="/portal" variant="body2" style={linkColor}>
                                Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </div>
                <ToastContainer />
            </div>
            <Box mt={5}>
                <Copyright />
            </Box>
        </Container>
    );
}