const allReports = [
  {
    type: "Report",
    year: "2019",
    quater: "Q1",
    disp: "2019 Q1",
    link: "https://drive.google.com/open?id=1xeRbCama7kWwVZ_R8dTeOlIyRlS1X3jL",
  },
  {
    type: "Erroneous Sensors",
    year: "2019",
    quater: "Q1",
    disp: "2019 Q1 - IA Sensors",
    link: "https://drive.google.com/open?id=13BZmroQCXye5_P1GDdTJ5yMPQLiSEta_",
  },
  {
    type: "Erroneous Sensors",
    year: "2019",
    quater: "Q1",
    disp: "2019 Q1 - KS Sensors",
    link: "https://drive.google.com/open?id=1iM_qIpytkMiI8RAEJwWmb3VlqnKQhdtL",
  },
  {
    type: "Report",
    year: "2019",
    quater: "Q2",
    disp: "2019 Q2",
    link: "https://drive.google.com/open?id=1nu9_zrAiye_RBn5QXJvoB2UMrIxZkpNd",
  },
  {
    type: "Erroneous Sensors",
    year: "2019",
    quater: "Q2",
    disp: "2019 Q2 - IA Sensors",
    link: "https://drive.google.com/open?id=1ZtyOtNtp1w5qF0hWdl3he5orW1b3GKv5",
  },
  {
    type: "Erroneous Sensors",
    year: "2019",
    quater: "Q2",
    disp: "2019 Q2 - KS Sensors",
    link: "https://drive.google.com/open?id=1mwyx3HV0MrrScn_YnoH2dJOnYU625BJq",
  },
  {
    type: "Report",
    year: "2019",
    quater: "Q3",
    disp: "2019 Q3",
    link: "https://drive.google.com/open?id=1RnoPal-ezReqyixmHFd0CQRbc9UZZY-9",
  },
  {
    type: "Erroneous Sensors",
    year: "2019",
    quater: "Q3",
    disp: "2019 Q3 - IA Sensors",
    link: "https://drive.google.com/open?id=1SZhieF2htbcHZUVbf3g5A82Qcmlm0hvh",
  },
  {
    type: "Erroneous Sensors",
    year: "2019",
    quater: "Q3",
    disp: "2019 Q3 - KS Sensors",
    link: "https://drive.google.com/open?id=1mbMtHB1Ru223zUm2HbOVyykZ0MoS7Y07",
  },
  {
    type: "Report",
    year: "2019",
    quater: "Annual Report",
    disp: "2019 Annual",
    link: "https://drive.google.com/open?id=15nyo_BzVPQuDeNGOaasEcK8jMAVE-Pam",
  },
  {
    type: "Erroneous Sensors",
    year: "2019",
    quater: "Annual",
    disp: "2019 Annual - IA Sensors",
    link: "https://drive.google.com/open?id=1xJCJ181hMvbqn4Tw2ty2DuMaFIKksTcl",
  },
  {
    type: "Erroneous Sensors",
    year: "2019",
    quater: "Annual",
    disp: "2019 Annual - KS Sensors",
    link: "https://drive.google.com/open?id=159-KQ5j38dIo7-zHWNXnf0clY_6SZHyC",
  },
  {
    type: "Report",
    year: "2020",
    quater: "Q1",
    disp: "2020 Q1",
    link: "https://drive.google.com/open?id=1oU1wP8zJ0XyhrvNSiWWRVGnU77lxAb1W",
  },
  {
    type: "Erroneous Sensors",
    year: "2020",
    quater: "Q1",
    disp: "2020 Q1 - IA Sensors",
    link: "https://drive.google.com/open?id=1dR6FnTATYD5E-Pd6xl8k1OPgCyTC-jE3",
  },
  {
    type: "Erroneous Sensors",
    year: "2020",
    quater: "Q1",
    disp: "2020 Q1 - KS Sensors",
    link: "https://drive.google.com/open?id=1kpKpn6pQdtNqr3ufKNP4hvaPKfNhRsh3",
  },
  {
    type: "Erroneous Sensors",
    year: "2020",
    quater: "Q1",
    disp: "2020 Q1 - MO Sensors",
    link: "https://drive.google.com/open?id=1vJ3OfKkcxt0Ly3ihtrDDLmdt-9EgDg3n",
  },
  {
    type: "Report",
    year: "2020",
    quater: "Q2",
    disp: "2020 Q2",
    link: "https://drive.google.com/file/d/1bMODfmIPZ-GF5fTMGvT8AYPoEZYDE7ku/view",
  },
  {
    type: "Erroneous Sensors",
    year: "2020",
    quater: "Q2",
    disp: "2020 Q2 - IA Sensors",
    link: "https://drive.google.com/file/d/1z3EqeP9SaH67BgyvjidJvkTpRi7308l2/view?usp=sharing",
  },
  {
    type: "Erroneous Sensors",
    year: "2020",
    quater: "Q2",
    disp: "2020 Q2 - KS Sensors",
    link: "https://drive.google.com/file/d/1Erk3VL9eBAqj9HBQ767zaHbihYEW41KM/view?usp=sharing",
  },
  {
    type: "Erroneous Sensors",
    year: "2020",
    quater: "Q2",
    disp: "2020 Q2 - MO Sensors",
    link: "https://drive.google.com/file/d/1F_RvuAeusFNjO3kK1jR0SnzdHMW2cxR9/view?usp=sharing",
  },
  {
    type: "Report",
    year: "2020",
    quater: "Q3",
    disp: "2020 Q3",
    link: "https://drive.google.com/file/d/1_EWyrijk-lQK6ZkCrGefryh4CEABpBuN/view?usp=sharing",
  },
  {
    type: "Erroneous Sensors",
    year: "2020",
    quater: "Q3",
    disp: "2020 Q3 - IA Sensors",
    link: "https://drive.google.com/file/d/1sqzX8eed2vgUQoXF7pIe8EMBk3or0bYU/view?usp=sharing",
  },
  {
    type: "Erroneous Sensors",
    year: "2020",
    quater: "Q3",
    disp: "2020 Q3 - KS Sensors",
    link: "https://drive.google.com/file/d/1Pho56RBRBQ8CRJGxWNehg78TlorxjidK/view?usp=sharing",
  },
  {
    type: "Erroneous Sensors",
    year: "2020",
    quater: "Q3",
    disp: "2020 Q3 - MO Sensors",
    link: "https://drive.google.com/file/d/10QA1AzXmLffs3Vh3MwA9zYWm6613DLHR/view?usp=sharing",
  },
  {
    type: "Report",
    year: "2020",
    quater: "Annual Report",
    disp: "2020 Annual",
    link: "https://drive.google.com/file/d/1fPt7wRQB7mgbRMYzQTaLLkobX5XBN_kN/view?usp=sharing",
  },
  {
    type: "Erroneous Sensors",
    year: "2020",
    quater: "Annual",
    disp: "2020 Annual - IA Sensors",
    link: "https://drive.google.com/file/d/1UoCLrnCUP3j8GgZXLvcKCAYm5xqtgKf1/view?usp=sharing",
  },
  {
    type: "Erroneous Sensors",
    year: "2020",
    quater: "Annual",
    disp: "2020 Annual - KS Sensors",
    link: "https://drive.google.com/file/d/1GcPkYifeQzSNmy13t72Ftd2HEppNnu-5/view?usp=sharing",
  },
  {
    type: "Erroneous Sensors",
    year: "2020",
    quater: "Annual",
    disp: "2020 Annual - MO Sensors",
    link: "https://drive.google.com/file/d/1piouIzopkV8NgO04EJBwTpLNmgkX2KVg/view?usp=sharing",
  },
  {
    type: "Report",
    year: "2021",
    quater: "Q1",
    disp: "2021 Q1",
    link: "https://drive.google.com/file/d/1voiY-pitucmYtnyjawRNaMGAxh7--4dY/view",
  },
  {
    type: "Erroneous Sensors",
    year: "2021",
    quater: "Q1",
    disp: "2021 Q1 - KS Sensors",
    link: "https://drive.google.com/file/d/1L1s1JjQGEM0HfB6TWwan2AwcKC51kAVE/view?usp=sharing",
  },
  {
    type: "Erroneous Sensors",
    year: "2021",
    quater: "Q1",
    disp: "2021 Q1 - MO Sensors",
    link: "https://drive.google.com/file/d/11DrMiWSFQUQCY4oi5zofDKaDI7WqgUAi/view?usp=sharing",
  },
  {
    type: "Report",
    year: "2021",
    quater: "Q2",
    disp: "2021 Q2",
    link: "https://drive.google.com/file/d/1O5m9lBXxCkZHB1AZ4Yxk2XpNL6-1liib/view?usp=sharing",
  },
  {
    type: "Erroneous Sensors",
    year: "2021",
    quater: "Q2",
    disp: "2021 Q2 - IA Sensors",
    link: "https://drive.google.com/file/d/1z3EqeP9SaH67BgyvjidJvkTpRi7308l2/view?usp=sharinghttps://docs.google.com/spreadsheets/d/15yV5KULkcqSpSHtEWOfqBl4IhDuIe0x6/edit?usp=sharing&ouid=114128508673891658631&rtpof=true&sd=true",
  },
  {
    type: "Erroneous Sensors",
    year: "2021",
    quater: "Q2",
    disp: "2021 Q2 - KS Sensors",
    link: "https://docs.google.com/spreadsheets/d/1k8Cnkhmn33Ye1a8IIiR2CCiRzbwRb2vP/edit?usp=sharing&ouid=114128508673891658631&rtpof=true&sd=true",
  },
  {
    type: "Erroneous Sensors",
    year: "2021",
    quater: "Q2",
    disp: "2021 Q2 - MO Sensors",
    link: "https://docs.google.com/spreadsheets/d/1dvUnfuP2WyfvgYuxMsZ7s3BGgYAolon4/edit?usp=sharing&ouid=114128508673891658631&rtpof=true&sd=true",
  },

  {
    type: "Report",
    year: "2021",
    quater: "Q3",
    disp: "2021 Q3",
    link: "https://drive.google.com/file/d/1ZOORdpaXzeTZ_wAddTJE8_w55lnlhBEG/view?usp=sharing",
  },
  {
    type: "Report",
    year: "2021",
    quater: "Annual Report",
    disp: "2021 Annual",
    link: "https://drive.google.com/file/d/1InQ9dfNH4gihQiHo2dbwCkOW5KjLwiBJ/view?usp=sharing",
  },
  {
    type: "Report",
    year: "2022",
    quater: "Q1",
    disp: "2022 Q1",
    link: "https://drive.google.com/file/d/1ekdk_rgoqi5Ysj-fW1uwHLqbQctLDUvK/view?usp=sharing",
  },
  {
    type: "Report",
    year: "2022",
    quater: "Q2",
    disp: "2022 Q2",
    link: "https://drive.google.com/file/d/1963P2pwTl-dvylUxCaZ_Faapm89uHbSA/view?usp=sharing",
  },
  {
    type: "Report",
    year: "2022",
    quater: "Q3",
    disp: "2022 Q3",
    link: "https://drive.google.com/file/d/1cB4W6hbT3gAjvl3s2JmGat9pXtC83eSa/view",
  },
  {
    type: "Erroneous Sensors",
    year: "2022",
    quater: "Q3",
    disp: "2022 Q3 - IA Sensors",
    link: "https://docs.google.com/spreadsheets/d/1RchRoxxUPYScNf2UUqwHv2VmdEQNU3yT/edit?usp=share_link&ouid=114128508673891658631&rtpof=true&sd=true",
  },
  {
    type: "Delay Definition",
    disp: "Delay Definition",
    link: "https://drive.google.com/file/d/156CnYVkFMMZW6cgopQKO-NYM09urdMAs/view?usp=sharing",
  },
];

export { allReports };
