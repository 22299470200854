import React, {useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import DnsRoundedIcon from '@material-ui/icons/DnsRounded';
import DashboardIcon from '@material-ui/icons/Dashboard';
import FilterIcon from '@material-ui/icons/Filter';
import PublicIcon from '@material-ui/icons/Public';
import Tooltip from '@material-ui/core/Tooltip';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import ReactGA from 'react-ga';
import { FaCalendar } from 'react-icons/fa';
import { MdSignalCellular2Bar, MdAnnouncement } from 'react-icons/md';
import { TiThSmall } from 'react-icons/ti';

import citySchema from '../data/citySchema';
import colors from '../themes/colors';
import * as Constants from './../utilities/Constants';
let themeType = citySchema['city']['heartland'].theme

const Icons = {
  HomeIcon: <HomeIcon style={{ fontSize: "20px" }} />,
  // EqualizerIcon:<EqualizerIcon style={{ fontSize: "20px" }} />,
  // DnsRoundedIcon: <DnsRoundedIcon style={{ fontSize: "20px" }} />,
  FaCalendar: <FaCalendar style={{ fontSize: "20px" }} />,
  PublicIcon: <PublicIcon style={{ fontSize: "20px" }} />,
  MdSignalCellular2Bar: <MdSignalCellular2Bar style={{ fontSize: "20px" }} />,
  TiThSmall: <TiThSmall style={{ fontSize: "20px" }} />,
  MdAnnouncement: <MdAnnouncement style={{ fontSize: "20px" }} />,
  DashboardIcon: <DashboardIcon style={{ fontSize: "20px" }} />,
  FilterIcon: <FilterIcon style={{ fontSize: "20px" }} />,
}


const styles = theme => ({
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  categoryHeaderPrimary: {
    color: 'rgba(103,113,114, 0.7)',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  item: {
    marginTop: 12,
    paddingTop: 1,
    paddingBottom: 1,
    //paddingLeft: 1,
    color: 'rgba(103,113,114, 1)',
    '&:hover,&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  itemCategory: {
    backgroundColor: colors.background[themeType],
    //boxShadow: '0 -1px 0 #404854 inset',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  itemCategory2: {
    backgroundColor: colors.background[themeType],
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  firebase: {
    fontSize: 24,
    color: theme.palette.common.white,
  },
  itemActiveItem: {
    color: '#3795b0',
  },
  itemPrimary: {
    fontSize: 'inherit',
    paddingLeft: 6,
  },
  itemIcon: {
    minWidth: 'auto',
    fontSize: '20px',
    marginLeft: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  drawer: {
    backgroundColor: '#0e0e0e !important',
    border: 'none !important',
    width: "250px;"
  },
  paperAnchorDockedLeft: {
    border: 'none',
  },
  muiListItemIconRoot: {
    //height: "50px !important",
  },
  tooltipPlacementLeft: {
    left: "-50px",
  }
});

function Navigator(props) {
  const { classes, view, getClicked, resetTab, ...other } = props;
  const [activeObject, setActive] = React.useState(citySchema['city'][view]['navs'][0]);

  useEffect(() => {
    ReactGA.initialize(Constants.GOOGLE_ANALYTICS_KEY);
    ReactGA.pageview(window.location.pathname + window.location.search); 
  }, []);

  /* Function to set the nav button, load the nav data and reset the tab */
  const onClick = (e) => { 
    ReactGA.event({
      category: "AdminLinkEvent",
      action: "e"
    })
    if (e !== "Home") {
      let tempActive = JSON.parse(JSON.stringify(activeObject))
      for (var value in tempActive) {
        tempActive[value] = false;
      }
      tempActive[e] = true
      setActive(tempActive)
      /* Send the clicked nav to parent component through props */
      getClicked(e)
      /* set the reset tab value in the header component */
      resetTab(true)

   
    }else{
      
    }
 
  }

  return (
    <Drawer variant="permanent" {...other} className={classes.drawer} classes={{ paperAnchorDockedLeft: classes.paperAnchorDockedLeft }}>
      <List disablePadding>
        <ListItem className={clsx(classes.firebase, classes.itemCategory2)}>
          <img src={require("../assets/etalyc.jpg")} width="50" style={{ marginLeft: "0px" }} alt="img"></img>
        </ListItem>
        <ListItem className={clsx(classes.item, classes.itemCategory)}>
          {/* <ListItemIcon className={classes.itemIcon}>
           
          </ListItemIcon> */}
          <ListItemText
            classes={{
              primary: classes.itemPrimary,
            }}
          >
          </ListItemText>
        </ListItem>
          <React.Fragment key='x'>
            <ListItem className={classes.categoryHeader}>
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                {/*id*/}
              </ListItemText>
            </ListItem>
            {citySchema['city'][view]['children'].map(({ id: childId, icon, active }) => (

              <ListItem
                key={childId}
                button
                onClick={() => onClick(childId)}
                className={clsx(classes.item, activeObject[childId] && classes.itemActiveItem)}
              >
                <Tooltip title={childId} placement="left-start" classes={{ tooltipPlacementLeft: classes.tooltipPlacementLeft }}>
                  <ListItemIcon className={classes.itemIcon} classes={{ root: classes.muiListItemIconRoot }}>{Icons[icon]}</ListItemIcon>
                  {/* <ListItemIcon className={classes.itemIcon} classes={{ root: classes.muiListItemIconRoot }}>{JSON.stringify(Icons)}</ListItemIcon> */}
                </Tooltip>
                <ListItemText
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                  key={childId}
                >
                </ListItemText>
              </ListItem>

            ))}
          </React.Fragment>
      </List>
    </Drawer>
  );
}

Navigator.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Navigator);