import React, { useState, useEffect } from 'react';

import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import Axios from 'axios';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import * as Constants from './../utilities/Constants';

function MadeWithLove() {
    return (
        <Typography variant="body2" color="textSecondary" align="center" style={{ paddingBottom: '25px' }}>
            {'Copyright © '}
            <Link color="inherit" href="https://etalyc.com/">
                Etalyc.com
        </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    container: {
        backgroundColor: '#ffffff',
        //padding: '5px',
    }
}));

export default function ResetPassword(props) {
    useEffect(() => {
        // get url
        let token = {
            token: props.match.params.token
        }
        Axios
            .post(Constants.NODE_API_BASE_URL+`/api/users/reset-password-confirm`, token)
            .then(res => {
                // toast.success("Successfully set password")
                // setlist(res['data']['users'])
            })
            .catch(err => {
                setEmsg(true)
                if (err.response.data.errors[0].error) {
                    toast.error(err.response.data.errors[0].error)
                    setErrorResponseMessage(err.response.data.errors[0].error)
                }
            })
    }, []);
 
    const classes = useStyles();
    const [errorResponseMessage, setErrorResponseMessage] = useState('');
    const [confirmEmailErrorMessage, setConfirmEmailErrorMessage] = useState(false);
    const [emsg, setEmsg] = useState(false);
    const divStyle = {
        width: '100%'
    };
    const errorMsg = {
        color: 'red',
        textAlign: 'center'
    }

    return (
        <Container component="main" maxWidth="xs" className={classes.container}>
            <CssBaseline />
            <div className={classes.paper}>
                <Grid item xs={12}>
                    <img src={require("../assets/etalyc.jpg")} width="400" alt="img" style={{ marginTop: '25px', marginBottom: '25px' }}></img>
                </Grid>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Reset Password
                </Typography>
                <div className={classes.form} noValidate>
                    <h4 style={errorMsg}>{errorResponseMessage}</h4>
                    {!emsg &&
                        <Formik
                            initialValues={{ password: '', cpassword: '' }}
                            onSubmit={(values, { setSubmitting }) => {
                                if (values.password === values.cpassword) {
                                    let user = {
                                        password: values.password,
                                        token: props.match.params.token
                                    }
                                    Axios.post(Constants.NODE_API_BASE_URL+`/api/users/reset-password-confirm`, user)
                                        .then(res => {
                                            toast.success("The password has been updated successfully.")
                                            setSubmitting(true);
                                            window.location.href = '/';
                                        }).catch(err => {
                                            setSubmitting(false);
                                            if (err.response.data.errors['message']) {
                                                setErrorResponseMessage(err.response.data.errors['message'])
                                            }
                                        })
                                } else {
                                    setConfirmEmailErrorMessage(true)
                                    setSubmitting(false);
                                    setErrorResponseMessage('Password and Confirm Password does not Matched!')
                                }

                            }
                            }

                            validationSchema={Yup.object().shape({
                                password: Yup.string()
                                    .required('Password is Required'),
                                cpassword: Yup.string()
                                    .required('Confirm Password is Required'),
                            })}
                        >
                            {(props) => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    isSubmitting,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                } = props;
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12}>
                                                <TextField style={divStyle}
                                                    type="password"
                                                    label="New Password"
                                                    name="password"
                                                    variant="outlined"
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={errors.password && touched.password}
                                                    helperText={(errors.password && touched.password) && errors.password}
                                                    margin="normal"
                                                    onClick={() => { setErrorResponseMessage('') }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <TextField style={divStyle}
                                                    type="password"
                                                    label="Confirm Password"
                                                    name="cpassword"
                                                    variant="outlined"
                                                    value={values.cpassword}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={errors.cpassword && touched.cpassword}
                                                    helperText={(errors.cpassword && touched.cpassword) && errors.cpassword}
                                                    margin="normal"
                                                    onClick={() => { setErrorResponseMessage('') }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            className={classes.submit}
                                            disabled={isSubmitting}
                                        >
                                            Submit
                                    </Button>


                                    </form>
                                );
                            }}
                        </Formik>
                    }
                </div>
            </div>
            <ToastContainer />
            <Box mt={5}>
                <MadeWithLove />
            </Box>
        </Container>
    );
}