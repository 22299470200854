import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { ToastContainer, toast } from 'react-toastify';
import { Formik } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import * as Constants from './../utilities/Constants';

function MadeWithLove() {

  return (
    <Typography variant="body2" color="textSecondary" align="center" style={{ paddingBottom: '25px', color: '#5d6163' }}>
      {'Copyright © '}
      <Link color="inherit" to="https://etalyc.com/" style={{ color: '#5d6163' }}>
        Etalyc.com
        </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  container: {
    backgroundColor: '#ffffff',
    //padding: '5px',
  }
}));

export default function SignIn(props) {
  const classes = useStyles();
  const [errorResponseMessage, setErrorResponseMessage] = useState('');
  const divStyle = {
    width: '100%'
  };
  const errorMsg = {
    color: 'red',
    textAlign: 'center'
  }
  const linkColor = {
    color: '#3f51b5',
  };

  return (
    <Container component="main" maxWidth="xs" className={classes.container}>
      <CssBaseline />
      <div className={classes.paper}>
        <Grid item xs={12}>
          <img src={require("../assets/etalyc.jpg")} width="400" alt="img" style={{ marginTop: '25px', marginBottom: '25px' }}></img>
        </Grid>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <div className={classes.form} noValidate>
          <h4 style={errorMsg}>{errorResponseMessage}</h4>
          <Formik
            initialValues={{ email: '', password: '' }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              let user = {
                email: values.email,
                password: values.password,
              }
              axios.post(Constants.NODE_API_BASE_URL + `/api/users/login`, { user: user }).then(res => {
                if (res.data.firstTimeLogin === true) {
                  props.history.push({
                    pathname: '/changePassword',
                    state: { email: res.data.user.email }
                  })
                } else {
                  window.location.href = '/dashboard'
                }
                localStorage.clear();
                toast.success("You are logged in successfully.")
                localStorage.setItem('mcomp_token', res.data.user.token);
                let token = localStorage.getItem('mcomp_token');
                console.log(token);
              }).catch(err => {
                setSubmitting(false);
                // toast.error("Something went wrong")
                if (err.response) {

                  //Sometime error message is commin in errors object and sometimes without object
                  // so we are handling it here 
                  if (err.response.data.errors && err.response.data.errors['message']) {
                    toast.error(err.response.data.errors['message']);
                    setErrorResponseMessage(err.response.data.errors['message']);
                  }

                  if (err.response.data.error) {
                    toast.error(err.response.data.error);
                    setErrorResponseMessage(err.response.data.error);
                  }
                }
              })

            }
            }

            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email()
                .required('Email is Required'),
              password: Yup.string()
                .required('Password is Required'),
            })}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
              } = props;
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <TextField style={divStyle}
                        // autoFocus
                        label="Email"
                        name="email"
                        variant="outlined"
                        className={classes.textField}
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.email && touched.email}
                        helperText={(errors.email && touched.email) && errors.email}
                        margin="normal"
                        onClick={() => { setErrorResponseMessage('') }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField style={divStyle}
                        type="password"
                        label="Password"
                        name="password"
                        variant="outlined"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.password && touched.password}
                        helperText={(errors.password && touched.password) && errors.password}
                        margin="normal"
                        onClick={() => { setErrorResponseMessage('') }}
                      />
                    </Grid>
                  </Grid>
                  <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                  />

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={isSubmitting}
                  >
                    Sign In
          </Button>
                  <Grid container>
                    <Grid item xs>
                      <Link to="/forgotPassword" variant="body2" style={linkColor}>
                        Forgot password?
              </Link>
                    </Grid>
                    <Grid item>
                      <Link to="/signup" variant="body2" style={linkColor}>
                        {"Don't have an account? Sign Up"}
                      </Link>
                    </Grid>
                  </Grid>

                </form>
              );
            }}
          </Formik>
          <ToastContainer />
        </div>
      </div>
      <Box mt={5}>
        <MadeWithLove />
      </Box>
    </Container>
  );
}