import React, { useState,useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Recaptcha from 'react-recaptcha';
import ReactGA from "react-ga";
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
// import * as Constants from './../utilities/Constants';
import * as Constants from '../../utilities/Constants';
import NewHeader from './NewHeader';


const ColorButton = withStyles((theme) => ({
    root: {
        color: '#fff',
        outline: 'none',
        width: '100%',
        margin: '10px 0px',
        backgroundColor: '#6ec5e7',
        '&:hover': {
            backgroundColor: '#6dc0e2',
        },
    },
}))(Button);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        // backgroundImage: `url(${"./map.png"})`,
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    avatar: {
        // margin: theme.spacing(1),
        margin: 'auto',
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    container: {
        backgroundColor: '#ffffff',
        //padding: '5px',
    }
}));

function NewContact(props) {
    const classes = useStyles();
    const [errorResponseMessage, setErrorResponseMessage] = useState('');
    const [captcha, setCaptcha] = useState(false);

    const divStyle = {
        width: '100%'
    };
    const errorMsg = {
        color: 'red',
        textAlign: 'center'
    }
    const linkColor = {
        color: '#3f51b5',
    };
    const recaptchaloaded = () => {
        setCaptcha(true)
    }
    const verifyCaptcha = (recaptchaToken) => {
        // console.log("res", recaptchaToken);
        if(recaptchaToken){
            setCaptcha(true);
        }
    }
   
    useEffect(() => {
        ReactGA.initialize(Constants.GOOGLE_ANALYTICS_KEY);
        ReactGA.pageview(window.location.pathname + window.location.search); 
      }, []);
    return (
        <div className={`${classes.root}`}>
            <Grid container>
                <Grid item xs={12}>
                    <NewHeader />
                </Grid>
                <Grid item xs={12} md={4} style={{ margin: ' auto' }}>
                    <div className="container">
                        <Paper className={`${classes.paper} mTop100`}>
                            <div className="text-center">
                                <Avatar className={classes.avatar}>
                                    <ContactSupportIcon />
                                </Avatar>
                                <Typography component="h1" variant="h5">
                                    Contact Us
                            </Typography>
                            </div>
                            <div className={classes.form} noValidate>
                                <h4 style={errorMsg}>{errorResponseMessage}</h4>
                                <Formik
                                    initialValues={{ first_name: '', last_name: '', email: '', message: '' }}
                                    onSubmit={(values, { setSubmitting }) => {
                                        setSubmitting(true);
                                        let user = {
                                            first_name: values.first_name,
                                            last_name: values.last_name,
                                            email: values.email,
                                            message: values.message,
                                        }
                                        if (captcha) {
                                            axios.post(Constants.NODE_API_BASE_URL + `/api/users/contactUs`, { user: user }).then(res => {
                                                toast.success("Thank you for contacting us. We will get back to you shortly.")

                                            }).catch(err => {
                                                setSubmitting(false);
                                                // toast.error("Something went wrong")
                                                if (err.response) {

                                                    //Sometime error message is commin in errors object and sometimes without object
                                                    // so we are handling it here 
                                                    if (err.response.data.errors && err.response.data.errors['message']) {
                                                        toast.error(err.response.data.errors['message']);
                                                        setErrorResponseMessage(err.response.data.errors['message']);
                                                    }

                                                    if (err.response.data.error) {
                                                        toast.error(err.response.data.error);
                                                        setErrorResponseMessage(err.response.data.error);
                                                    }
                                                }
                                            })
                                        }else{
                                            setErrorResponseMessage("Captcha verification failed. Please try again.");
                                            console.log("Captcha verification failed. Please try again.");
                                            setSubmitting(false);
                                        }

                                    }
                                    }

                                    validationSchema={Yup.object().shape({
                                        first_name: Yup.string()
                                            .required('First Name is Required'),
                                        last_name: Yup.string()
                                            .required('Last Name is Required'),
                                        email: Yup.string()
                                            .email()
                                            .required('Email is Required'),
                                        message: Yup.string()
                                            .required('Message is Required'),
                                    })}
                                >
                                    {(props) => {
                                        const {
                                            values,
                                            touched,
                                            errors,
                                            isSubmitting,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                        } = props;
                                        return (
                                            <form onSubmit={handleSubmit}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={12}>
                                                        <TextField style={divStyle}
                                                            // autoFocus
                                                            label="First Name"
                                                            name="first_name"
                                                            variant="outlined"
                                                            className={classes.textField}
                                                            value={values.first_name}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.first_name && touched.first_name}
                                                            helperText={(errors.first_name && touched.first_name) && errors.first_name}
                                                            // margin="normal"
                                                            onClick={() => { setErrorResponseMessage('') }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12}>
                                                        <TextField style={divStyle}
                                                            // autoFocus
                                                            label="Last Name"
                                                            name="last_name"
                                                            variant="outlined"
                                                            className={classes.textField}
                                                            value={values.last_name}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.last_name && touched.last_name}
                                                            helperText={(errors.last_name && touched.last_name) && errors.last_name}
                                                            // margin="normal"
                                                            onClick={() => { setErrorResponseMessage('') }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12}>
                                                        <TextField style={divStyle}
                                                            // autoFocus
                                                            label="Email"
                                                            name="email"
                                                            variant="outlined"
                                                            className={classes.textField}
                                                            value={values.email}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.email && touched.email}
                                                            helperText={(errors.email && touched.email) && errors.email}
                                                            // margin="normal"
                                                            onClick={() => { setErrorResponseMessage('') }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12}>
                                                        <TextField style={divStyle}

                                                            label="Message"
                                                            name="message"
                                                            variant="outlined"
                                                            className={classes.textField}
                                                            value={values.message}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={errors.message && touched.message}
                                                            helperText={(errors.message && touched.message) && errors.message}
                                                            // margin="normal"
                                                            onClick={() => { setErrorResponseMessage('') }}
                                                            id="message"

                                                            multiline
                                                            rows={4}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12}>
                                                        <Recaptcha
                                                            sitekey="6Lc--6gZAAAAAJkgbsS6GndtvR-HG0SGEusuQO3Y"
                                                            //render="explicit"
                                                            verifyCallback={recaptchaloaded}
                                                            // onloadCallback={recaptchaloaded}
                                                        />
                                                    </Grid>

                                                </Grid>
                                                <ColorButton
                                                    type="submit"
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.submit}
                                                    disabled={isSubmitting}
                                                >
                                                    Send
                                                </ColorButton>


                                            </form>
                                        );
                                    }}
                                </Formik>
                                <ToastContainer />
                            </div>
                        </Paper>
                    </div>
                </Grid>

            </Grid>

            <Grid item xs={12}>
                <Paper className={`${classes.paper} bgFooter`}>
                    <hr />
                    <p className="footerNew">Copyright © Etalyc 2020.</p>
                </Paper>
            </Grid>

        </div>
    )
}

export default NewContact

